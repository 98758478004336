import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircle } from "react-icons/io";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMyContext } from '../../context/MyContext';
import { FaWarehouse } from "react-icons/fa6";
import '../../css/custom-scrollbar.css';
import { Base_Url, editwarehouse,getpincode } from '../../config/config';
import toast from 'react-hot-toast';
import axios from 'axios';

const schema = z.object({
  // orderId: z.string()
  //   .min(1, "Order ID is required")
  //   .regex(/^\d+$/, "Order ID must be numeric"),
  Name: z.string()
    .min(1, "Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabetic characters"),

  email: z.string()
    .email("Invalid email address"),
  phone: z.string()
    .length(10, "Phone number must be exactly 10 digits")
    .regex(/^\d+$/, "Phone number must be numeric"),
  contact_person: z.string()
    .min(1, "contact_person is required")
    .regex(/^[a-zA-Z\s]+$/, "contact_person must only contain alphabetic characters"),
  // status: z.string().min(1, "Status is required"),
  date: z.string().min(1, "Add Date is required"),
  address: z.string().min(1, "Address is required"),
  pincode: z.string()
    .length(6, "Pincode must be exactly 6 characters")
    .regex(/^\d+$/, "Pincode must be numeric"),
  city: z.string()
    .min(1, "City is required")
    .regex(/^[A-Za-z\s]+$/, "City must only contain letters and spaces"),
  state: z.string()
    .min(1, "State is required")
    .regex(/^[A-Za-z\s]+$/, "State must only contain letters and spaces"),
  country: z.string()
    .min(1, "Country is required")
    .regex(/^[A-Za-z\s]+$/, "Country must only contain letters and spaces"),
  registerName: z.string()
    .min(1, "Register Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Register Name must only contain alphabetic characters"),
  returnAddress: z.string().optional(),
  returnPhone: z.string().optional(),
  returnPincode: z.string().optional(),
  returnCity: z.string().optional(),
  returnState: z.string().optional(),
  returnCountry: z.string().optional(),
});

const ViewWarehouse = ({ isopen, onClose, warehouse }) => {
  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');
  const { isDarkMode, setLoading } = useMyContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditId,isSetEditId] = useState (true);
  const [isEditEmail,isSetEditEmail] = useState (true);
  const { register, handleSubmit, formState: { errors },setValue,reset } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (warehouse) {
      reset({
        Id: warehouse.id || '',
        Name: warehouse.name || '',
        email: warehouse.email || '',
        phone: warehouse.phone || '',
        contact_person: warehouse.contact_person || '',
        date: warehouse.create_date || '',
        address: warehouse.address || '',
        pincode: warehouse.pincode || '',
        city: warehouse.city || '',
        state: warehouse.state || '',
        country: warehouse.country || '',
        registerName: warehouse.name || '',
        returnAddress: warehouse.return_address || '',
        returnPhone: warehouse.return_phone || '',
        returnPincode: warehouse.return_pincode || '',
        returnCity: warehouse.return_city || '',
        returnState: warehouse.return_state || '',
        returnCountry: warehouse.return_country || '',
      });
    }
  }, [warehouse, reset]);



  const onErrors = (errors) => {
    console.log("Validation Errors:", errors);
    toast.error("Please fix the errors in the form.");
  };

  const onSubmit = async (data) => {
    const editWarehouseurl = Base_Url + editwarehouse 
    try {
      const payload = {
        customer_id: cusid,
        id:warehouse.id,
        name: data.Name,
        email: data.email,
        phone: data.phone,
        contact_person: data.contact_person,
        address: data.address,
        pincode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        return_address: data.returnAddress,
        return_phone: data.returnPhone,
        return_pincode: data.returnPincode,
        return_city: data.returnCity,
        return_state: data.returnState,
        return_country: data.returnCountry,
        create_date: data.date,
      };
      const response = await axios.post(editWarehouseurl, payload, {
        headers: {
          'token': token,
          'cus_id': cusid,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.status === "success") {
        toast.success(response.data.message);
        setIsEditMode(false);
        onClose();
      } else {
        toast.error(response.data.message || 'Failed to update warehouse');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred while updating warehouse');
    }
  };

  const handleClose = () => {
    setIsEditMode(false);
    onClose();
  };

  const hanldeAllEditFileds = ()=>{
    setIsEditMode(!isEditMode);
    // isSetEditId(isEditId); 
    // isSetEditEmail(isEditEmail);
  }


  const fetchPincodeData = async (pincode) => {
    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');
    const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`;
    try {
        const response = await fetch(urlgetPincode, {
            method: 'GET',
            headers: {
                'token': token,
                'cus_id': cus_id,
                'Content-Type': 'application/json'
            }
        });


        const data = await response.json();
        if (data && data.status === "SUCCESS") {
            let { city, state } = data.data[0];
      
            city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
            state = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase();
            setValue("city", city);
            setValue("state", state);
        }


        else if (data && data.message === "Unauthorized access - Invalid token") {
            toast.error(data.message + " Please login Again");
        }

        else if (data && data.status === "FAIL") {
            toast.error("Something Went Wrong Please Try Again With Another Pincode");
        }


        else {
            toast.error("Failed to fetch city and state data");
        }
    } catch (error) {
        console.error('Error fetching pincode data:', error);
        toast.error("Error fetching pincode data");
    }
};


const fetchreturnPincodeData = async (returnPincode) => {
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const urlgetPincode = `${Base_Url}${getpincode}?pincode=${returnPincode}`;
  try {
      const response = await fetch(urlgetPincode, {
          method: 'GET',
          headers: {
              'token': token,
              'cus_id': cus_id,
              'Content-Type': 'application/json'
          }
      });


      const data = await response.json();
      if (data && data.status === "SUCCESS") {
          let { city, state } = data.data[0];
    
          city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
          state = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase();

          setValue("returnCity", city);
          setValue("returnState", state);
      }
      else if (data && data.message === "Unauthorized access - Invalid token") {
          toast.error(data.message + " Please login Again");
      }

      else if (data && data.status === "FAIL") {
          toast.error("Something Went Wrong Please Try Again With Another Pincode");
      }


      else {
          toast.error("Failed to fetch city and state data");
      }
  } catch (error) {
      console.error('Error fetching pincode data:', error);
      toast.error("Error fetching pincode data");
  }
};


const handleInputChange = (event) => {

  const { value, id } = event.target;
  if (['pincode', 'phone', 'return-pincode'].includes(id)) {
    const filteredValue = value.replace(/[^0-9]/g, '');
    event.target.value = filteredValue;
    // Trigger API call if input reaches 6 digits for `return-pincode`
    if (id === 'return-pincode' && filteredValue.length === 6) {
      fetchreturnPincodeData(filteredValue);
    }
    if (id === 'pincode' && filteredValue.length === 6) {
    fetchPincodeData(filteredValue);
    }
  }
  if (['facility-name', 'city', 'contact-person', 'state', 'returnState', 'returnCity', 'returnCountry', 'country'].includes(id)) {
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
    event.target.value = filteredValue;
    
  }
};

  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-customPurple text-white relative  shadow-custom-light dark:shadow-custom-dark ">
        <h2
          className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaWarehouse className="mr-2" size={24} />
          WareHouse Details
        </h2>
        <IoIosCloseCircle
          className="absolute  top-0 right-0 cursor-pointer"
          size={32}
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
        <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom-custom">

          <form className="space-y-6 font-sans" onSubmit={handleSubmit(onSubmit, onErrors)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">ID</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Id')}
                  disabled={isEditId}
                />
                {errors.Id && <p className="text-red-500 text-sm">{errors.Id.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Name</label>
                <input
                  type="text"
                   id="facility-name"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Name')}
                  disabled={!isEditMode}
                />
                {errors.Name && <p className="text-red-500 text-sm">{errors.Name.message}</p>}
              </div>
            </div>

            {/* Additional form fields following the same structure */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Email</label>
                <input
                  type="email"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('email')}
                  disabled={isEditEmail}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Contact Person</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('contact_person')}
                  disabled={!isEditMode}
                />
                {errors.contact_person && <p className="text-red-500 text-sm">{errors.contact_person.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('phone')}
                  maxLength={10}
                  disabled={!isEditMode}
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Add Date</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('date')}
                  disabled={!isEditMode}
                />
                {errors.date && <p className="text-red-500 text-sm">{errors.date.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address</label>
                <input
                  type="text"
                  id="address"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('address')}
                  disabled={!isEditMode}
                />
                {errors.address && <p className="text-red-500 text-sm">{errors.address.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pincode</label>
                <input
                  type="text"
                  id="pincode"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('pincode')}
                  onChange={handleInputChange}
                  maxLength={6}
                  disabled={!isEditMode}
                />
                {errors.pincode && <p className="text-red-500 text-sm">{errors.pincode.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">City</label>
                <input
                  type="text"
                  id="city"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('city')}
                  disabled={!isEditMode}
                />
                {errors.city && <p className="text-red-500 text-sm">{errors.city.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">State</label>
                <input
                  type="text"
                  id="state"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('state')}
                  disabled={!isEditMode}
                />
                {errors.state && <p className="text-red-500 text-sm">{errors.state.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Country</label>
                <input
                  type="text"
                  id='country'
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('country')}
                  disabled={!isEditMode}
                />
                {errors.country && <p className="text-red-500 text-sm">{errors.country.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Register Name</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('registerName')}
                  disabled={!isEditMode}
                />
                {errors.registerName && <p className="text-red-500 text-sm">{errors.registerName.message}</p>}
              </div>
            </div>

            {/* Optional Return Address fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Address</label>
                <input
                  type="text"
                  id="returnAddress"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnAddress')}
                  disabled={!isEditMode}
                />
                {errors.returnAddress && <p className="text-red-500 text-sm">{errors.returnAddress.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Phone</label>
                <input
                  type="tel"
                  id="returnPhone"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnPhone')}
                  maxLength={10}
                  disabled={!isEditMode}
                />
                {errors.returnPhone && <p className="text-red-500 text-sm">{errors.returnPhone.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Pincode</label>
                <input
                  type="text"
                  id = 'return-pincode'
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnPincode')}
                  onChange={handleInputChange}
                  maxLength={6}
                  disabled={!isEditMode}
                />
                {errors.returnPincode && <p className="text-red-500 text-sm">{errors.returnPincode.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return City</label>
                <input
                  type="text"
                  id = "returnCity"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnCity')}
                  disabled={!isEditMode}
                />
                {errors.returnCity && <p className="text-red-500 text-sm">{errors.returnCity.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return State</label>
                <input
                  type="text"
                   id = "returnState"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnState')}
                  disabled={!isEditMode}
                />
                {errors.returnState && <p className="text-red-500 text-sm">{errors.returnState.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Country</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnCountry')}
                  disabled={!isEditMode}
                />
                {errors.returnCountry && <p className="text-red-500 text-sm">{errors.returnCountry.message}</p>}
              </div>
            </div>

            <button
              type="button"
              onClick={hanldeAllEditFileds}
              className="bg-customPurple text-white py-2 px-4 rounded-custom-custom hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
            >
              {isEditMode ? 'Cancel' : 'Edit'}
            </button>

            {(isEditMode && isEditId && isEditEmail) ? (
              <button
                type="submit"
                className="bg-customPurple text-white py-2 px-4 md:ml-8 rounded-custom-custom mt-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
              >
                Save
              </button>
            ):("")
          }
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewWarehouse;
