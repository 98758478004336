
import { useEffect, useState } from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { FaWeight } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi';
import { useMyContext } from '../../../context/MyContext';
import StatusRaised from '../../../component/models/statusRaised';
import '../../../css/custom-scrollbar.css'
import toast from 'react-hot-toast';
import axios from 'axios';
import { Base_Url, dispute_action, weightListByStatus, weightListStatusBylosed, weightListStatusByAccept, weightDisputesAllforUser } from '../../../config/config'


const WeightDisputeDashboard = () => {
  const [activeTab, setActiveTab] = useState('action');
  const [selectAll, setSelectAll] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [actionRequiredData, setActionrequiredData] = useState([]);
  const [acceptData, setAcceptData] = useState([]);
  const [closedData, setClosedData] = useState([]);
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCustomer, setTotalCustomers] = useState(0)
  const [selectedWaybillNo, setSelectedWaybillNo] = useState()
  const [allWeightDisputess, setAllWeightDisputes] = useState([]);
  const [allSellerId, setAllSelerId] = useState();

  const handleViewClick = (waybill) => {
    setSelectedWaybillNo(waybill)
    setIsPopupOpen(true);
  };
  const handleClose = () => {
    setIsPopupOpen(false);
  };


  // useEffect(()=>{
  //   getWeightDisputesDataById()
  // },[limit,currentPage])



  useEffect(() => {
    if (activeTab === 'accepted') {
      getAcceptData();
    } else if (activeTab === 'action') {
      getWeightDisputesData();
    }
    else if (activeTab === 'disputes') {
      allWeightDisputes();
    }
    else if (activeTab === 'closed') {
      getClosedData();
    }
  }, [activeTab, limit, currentPage]);

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePaginationChange = (e, value) => {
    setCurrentPage(value);
  };


  const tabs = [
    { id: 'action', label: 'Action Required Count' },
    { id: 'closed', label: 'Closed Count' },
    { id: 'accepted', label: 'Accepted' },
    { id: 'disputes', label: 'All Disputes' },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setTotalCustomers(0);
  }
  const getWeightDisputesData = async () => {
    const url = `${Base_Url}${weightListByStatus}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setActionrequiredData(data.data);
        setTotalCustomers(data.pagination.total_records);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const getClosedData = async () => {
    const url = `${Base_Url}${weightListStatusBylosed}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setClosedData(data.data);
        setTotalCustomers(data.pagination.total_records);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const getAcceptData = async () => {
    const url = `${Base_Url}${weightListStatusByAccept}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setAcceptData(data.data);
        setTotalCustomers(data.pagination.total_records);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const allWeightDisputes = async () => {
    const url = `${Base_Url}${weightDisputesAllforUser}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setAllWeightDisputes(data.data);
        setTotalCustomers(data.pagination.total_records);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleAccept = async (row) => {
    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');

    const formData = new FormData();
    formData.append("waybill", row.waybill);
    formData.append("status", "Accept");
    formData.append("amount", row.price);
    formData.append("seller_id", row.seller_id);

    try {
      const response = await axios.post(Base_Url + dispute_action, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': token,
          'cus_id': cus_id,
        },
      });
      if (response.data.status === "SUCCESS") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error uploading data: " + error.message);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows([...Array(actionRequiredData.length).keys()]);
      setAllSelerId([...Array(actionRequiredData.seler_id)]);
    } else {
      setSelectedRows([]);
      setAllSelerId('');
    }
    setSelectAll(e.target.checked);
  };

   console.log("sleleris",allSellerId);
  const handleRowSelect = (index) => {
    setSelectedRows((prev) => {
      const isSelected = prev.includes(index);
      if (isSelected) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     // Select all shipments by their IDs
  //     const allIds = actionRequiredData.map((id) => id.seller_id); // Replace 'seler_id' with the correct unique identifier
  //     setSelectedRows(allIds);
  //   } else {
  //     setSelectedRows([]);
  //   }
  //   setSelectAll(e.target.checked);
  // };
  
  // const handleRowSelect = (seller_id) => {
  //   setSelectedRows((prev) => {
  //     const isSelected = prev.includes(seller_id);
  //     if (isSelected) {
  //       // Remove from selected rows
  //       return prev.filter((id) => id !== seller_id);
  //     } else {
  //       // Add to selected rows
  //       return [...prev, seller_id];
  //     }
  //   });
  // };
  
  // // Dynamic "Select All" checkbox state
  // useEffect(() => {
  //   setSelectAll(selectedRows.length === actionRequiredData.length && actionRequiredData.length > 0);
  // }, [selectedRows, actionRequiredData]);
    

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
            <Sidebar />
          </div>
        )}
        <div className="container mx-auto p-4 shadow-custom-light rounded-custom md:mt-4 md:mb-4 md:mr-4 md:ml-4 mb-2 ml-2 mr-2 mt-8 dark:shadow-custom-dark custom-scrollbar overflow-y-auto">
          {/* <h1 className="text-2xl font-bold mb-4">Weight Dispute</h1> */}
          <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
            <FaWeight className="mr-2" size={24} />
            Weight Disputes
          </button>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`px-4 py-2 mr-8 w-full mb-2 rounded dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab.id
                  ? 'bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700 text-white dark:bg-customPurple dark:text-white'
                  : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                  }`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          {
            selectAll ? (
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4' >
                <button
                  className="px-4 py-2 w-full mr-8 mb-2 rounded bg-green-500 dark:text-white dark:shadow-custom-dark 
                  'bg-customPurple hover:bg-green-600  text-white  dark:text-white'
                  dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom">
                  Accepted In Bulk
                </button>
                <button
                  className="px-4 py-2 mr-8 w-full mb-2 rounded bg-red-500 dark:text-white dark:shadow-custom-dark 
                  'bg-customPurple hover:bg-red-600  text-white  dark:text-white'
                  dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom">
                  Raised In Bulk
                </button>
              </div>
            ) : ("")
          }
          <div className="overflow-x-auto shadow-custom-light rounded-custom dark:shadow-none">
            {activeTab === 'action' && (
              <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                <div className='overflow-x-auto'>
                  <table className='min-w-full table-auto'>
                    <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                      <tr>
                        <th className='p-2 text-left'>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className='p-2 text-left'>AWB No</th>
                        <th className='p-2 text-left'>Initial <br></br> Weight</th>
                        <th className='p-2 text-left'>Dimensions</th>
                        <th className='p-2 text-left'>Description</th>
                        <th className='p-2 text-left'>SKU</th>
                        <th className='p-2 text-left'>Status</th>
                        <th className='p-2 text-left'>Revised<br></br> Weight</th>
                        <th className='p-2 text-left'>Price</th>
                        <th className='p-2 text-left'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actionRequiredData.map((row, index) => (
                        <tr key={index} className="border-b">
                          <td className='p-2'>
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(index)}
                              onChange={() => handleRowSelect(index)}
                            />
                          </td>
                          <td className="p-4">{row.waybill}</td>
                          <td className="p-4">{row.initial_weight}</td>
                          <td className="p-4">{row.dimension}</td>
                          <td className="p-4">{row.product_des}</td>
                          <td className="p-4">{row.sku}</td>
                          <td className="p-4">{row.status}</td>
                          <td className="p-4">{row.reversed_weight}</td>
                          <td className="p-4">{row.price}</td>
                          <td className="p-4">
                            <button
                              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                              onClick={() => handleAccept(row)}
                            >
                              Accept
                            </button>
                            <button
                              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ml-2"
                              onClick={() => handleViewClick(row.waybill)}
                            >
                              Raise
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <StatusRaised
                  isopen={isPopupOpen}
                  onClose={handleClose}
                  waybill_no={selectedWaybillNo} />
                <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                  <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Weight Disputes: ${totalCustomer}`}</span>
                  <select
                    id="limit"
                    value={limit}
                    onChange={handleLimitChange}
                    className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={totalCustomer}>All</option>
                  </select>
                  <Pagination
                    count={Math.ceil(totalCustomer / limit)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {activeTab === 'accepted' && (
              <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                <div cla ssName='overflow-x-auto'>
                  <table className='min-w-full table-auto'>
                    <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                      <tr>
                        <th className='p-2 text-left'>AWB No</th>
                        <th className='p-2 text-left'>Initial<br></br>Weight</th>
                        <th className='p-2 text-left'>Dimensions</th>
                        <th className='p-2 text-left'>Description</th>
                        <th className='p-2 text-left'>SKU</th>
                        <th className='p-2 text-left'>Revised<br></br>Weight</th>
                        <th className='p-2 text-left'>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {acceptData.map((row, index) => (
                        <tr key={index} className='border-b'>
                          <td className="p-2">{row.waybill}</td>
                          <td className="p-2">{row.initial_weight}</td>
                          <td className="p-2">{row.dimension}</td>
                          <td className="p-2">{row.product_des}</td>
                          <td className="p-2">{row.sku}</td>
                          <td className="p-2">{row.reversed_weight}</td>
                          <td className="p-2">{row.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                  <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Weight Disputes: ${totalCustomer}`}</span>
                  <select
                    id="limit"
                    value={limit}
                    onChange={handleLimitChange}
                    className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={totalCustomer}>All</option>
                  </select>
                  <Pagination
                    count={Math.ceil(totalCustomer / limit)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {activeTab === 'disputes' && (
              <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                <div className='overflow-x-auto'>
                  <table className='min-w-full table-auto'>
                    <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                      <tr>
                        <th className='p-2 text-left'>AWB No</th>
                        <th className='p-2 text-left'> Initial<br></br>Weight</th>
                        <th className='p-2 text-left'>Dimensions</th>
                        <th className='p-2 text-left'>Description</th>
                        <th className='p-2 text-left'>SKU</th>
                        <th className='p-2 text-left'>Status</th>
                        <th className='p-2 text-left'>Revised <br></br> Weight</th>
                        <th className='p-2 text-left'>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allWeightDisputess.map((row, index) => (
                        <tr key={index} className='border-b'>
                          <td className="p-2">{row.waybill}</td>
                          <td className="p-2">{row.initial_weight}</td>
                          <td className="p-2">{row.dimension}</td>
                          <td className="p-2">{row.product_des}</td>
                          <td className="p-2">{row.sku}</td>
                          <td className="p-2">{row.status}</td>
                          <td className="p-2">{row.reversed_weight}</td>
                          <td className="p-2">{row.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                  <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Weight Disputes: ${totalCustomer}`}</span>
                  <select
                    id="limit"
                    value={limit}
                    onChange={handleLimitChange}
                    className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={totalCustomer}>All</option>
                  </select>
                  <Pagination
                    count={Math.ceil(totalCustomer / limit)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {activeTab === 'closed' && (
              <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                <div className='overflow-x-auto'>
                  <table className='min-w-full table-auto'>
                    <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                      <tr>
                        <th className='p-2 text-left'>AWB No</th>
                        <th className='p-2 text-left'>Initial<br></br> Weight</th>
                        <th className='p-2 text-left'>Dimensions</th>
                        <th className='p-2 text-left'>Description</th>
                        <th className='p-2 text-left'>SKU</th>
                        <th className='p-2 text-left'>Status</th>
                        <th className='p-2 text-left'> Revised  <br></br>Weight</th>
                        <th className='p-2 text-left'>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {closedData.map((row, index) => (
                        <tr key={index} className='border-b'>
                          <td className="p-2">{row.waybill}</td>
                          <td className="p-2">{row.initial_weight}</td>
                          <td className="p-2">{row.dimension}</td>
                          <td className="p-2">{row.product_des}</td>
                          <td className="p-2">{row.sku}</td>
                          <td className="p-2">{row.status}</td>
                          <td className="p-2">{row.reversed_weight}</td>
                          <td className="p-2">{row.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                  <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Weight Disputes: ${totalCustomer}`}</span>
                  <select
                    id="limit"
                    value={limit}
                    onChange={handleLimitChange}
                    className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                  >
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={totalCustomer}>All</option>
                  </select>
                  <Pagination
                    count={Math.ceil(totalCustomer / limit)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {(activeTab === 'closed' || activeTab === 'accepted' || activeTab === 'disputes').lenght === 0 && (
              <p className="text-gray-600">No data available for this tab.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WeightDisputeDashboard;