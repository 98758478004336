import React, { useEffect, useState } from 'react';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import '../../../css/custom-scrollbar.css';
import { Base_Url, ratecardList } from '../../../config/config';
import { FcMoneyTransfer } from "react-icons/fc";
import toast from 'react-hot-toast';


const RateCard = () => {
  const { isDarkMode } = useMyContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isToggleSidebar } = useMyContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const itemsPerPage = 8;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const [currentTab, setCurrentTab] = useState('surface');
  useEffect(() => {
    fetchRateCardList()
  }, [])

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNavigation = () => {
    navigate('/dashboard');
  };


  const fetchRateCardList = async () => {
    try {
      const url = Base_Url + ratecardList

      const response = await fetch(url +"/" + cus_id, {
        method: 'GET',
        headers: {
          token: token || '',
          cus_id: cus_id || '',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch data');
      }

      const result = await response.json();

      if (result && result.data) {
        setData(result.data);
      } else {
        console.error('Unexpected response structure:', result);
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('API error:', error.message);
      toast.error('Error fetching order data: ' + error.message);
    }
  };

  return (
    <>
      <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>

        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
              style={
                window.innerWidth < 768
                  ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                  : {}
              }>
              <Sidebar />
            </div>
          )}
          <div className={`shadow-custom-light ml-3 mt-6 mb-6 mr-6 w-full rounded-custom  custom-scrollbar overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>
            <button onClick={handleNavigation}
              className="text-xl w-full sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px]  sm:w-full flex items-center justify-center">
              {/* <FaUser className="mr-2" size={24} /> */}
              <FcMoneyTransfer className="mr-2" size={24} />
              Rate Card
            </button>
            <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)]  rounded-custom text-white" : "bg-white "}`}>
              <div className="overflow-x-auto">
                <div className="overflow-x-auto">
                  <table className="min-w-full table-auto border-collapse">
                    <thead className="bg-[#0540a6dd] text-white text-[14px]">
                      <tr>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">Courier Name</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">Within City</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">Within State</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">Metro</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">Rest of India</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">J&K, NorthEast</th>
                        <th className="p-3 text-left font-semibold border-b-2 border-blue-400">COD Charges</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 && Array.from(new Set(data.map(item => item.partner))).map((partner, index) => {
                        const zoneA = data.find(item => item.partner === partner && item.zone_name === 'A');
                        const zoneB = data.find(item => item.partner === partner && item.zone_name === 'B');
                        const zoneC = data.find(item => item.partner === partner && item.zone_name === 'C');
                        const zoneD = data.find(item => item.partner === partner && item.zone_name === 'D');
                        const zoneE = data.find(item => item.partner === partner && item.zone_name === 'E');

                        return (
                          <tr key={partner} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-200 ${isDarkMode ? 'dark:bg-opacity-10 dark:hover:bg-opacity-20' : ''}`}>
                            <td className="p-3 border-b border-gray-200">
                              <span className="font-medium capitalize">{partner}</span>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                                <div className="font-medium text-blue-600 dark:text-blue-400">Express: ₹{zoneA?.express_price || '-'}</div>
                                <div className="text-gray-600 dark:text-gray-300">Surface: ₹{zoneA?.surface_price || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Express/500g: ₹{zoneA?.additional_expressprice_per500gm || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Surface/500g: ₹{zoneA?.additional_surfaceprice_per500gm || '-'}</div>
                              </div>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                                <div className="font-medium text-blue-600 dark:text-blue-400">Express: ₹{zoneB?.express_price || '-'}</div>
                                <div className="text-gray-600 dark:text-gray-300">Surface: ₹{zoneB?.surface_price || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Express/500g: ₹{zoneB?.additional_expressprice_per500gm || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Surface/500g: ₹{zoneB?.additional_surfaceprice_per500gm || '-'}</div>
                              </div>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                                <div className="font-medium text-blue-600 dark:text-blue-400">Express: ₹{zoneC?.express_price || '-'}</div>
                                <div className="text-gray-600 dark:text-gray-300">Surface: ₹{zoneC?.surface_price || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Express/500g: ₹{zoneC?.additional_expressprice_per500gm || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Surface/500g: ₹{zoneC?.additional_surfaceprice_per500gm || '-'}</div>
                              </div>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                                <div className="font-medium text-blue-600 dark:text-blue-400">Express: ₹{zoneD?.express_price || '-'}</div>
                                <div className="text-gray-600 dark:text-gray-300">Surface: ₹{zoneD?.surface_price || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Express/500g: ₹{zoneD?.additional_expressprice_per500gm || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Surface/500g: ₹{zoneD?.additional_surfaceprice_per500gm || '-'}</div>
                              </div>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                                <div className="font-medium text-blue-600 dark:text-blue-400">Express: ₹{zoneE?.express_price || '-'}</div>
                                <div className="text-gray-600 dark:text-gray-300">Surface: ₹{zoneE?.surface_price || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Express/500g: ₹{zoneE?.additional_expressprice_per500gm || '-'}</div>
                                <div className="text-[12px] text-black dark:text-gray-100">Add. Surface/500g: ₹{zoneE?.additional_surfaceprice_per500gm || '-'}</div>
                              </div>
                            </td>
                            <td className="p-3 border-b border-gray-200">
                              <div className="space-y-1">
                              <div className="text-gray-600 dark:text-gray-300">COD %: {zoneA?.cod_percentage || '-'}</div>
                                <div className="font-medium text-green-600 dark:text-green-400">Min COD: ₹{zoneA?.min_cod_price || '-'}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCard;
