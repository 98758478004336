import React from 'react';
import {
  NetworkIcon,
  CheckCircleIcon,
  LocateIcon,
  ShieldCheckIcon,
  TruckIcon,
  DatabaseIcon
} from 'lucide-react';
import { GrServices } from "react-icons/gr";
import { FaNetworkWired, FaCheck, FaMapMarkerAlt, FaShieldAlt, FaTruck, FaDatabase } from 'react-icons/fa';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';

const LogisticsServicePage = () => {
  const { isDarkMode, isToggleSidebar } = useMyContext();

  const services = [
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-blue-400/20 dark:bg-blue-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-blue-200 dark:group-hover:shadow-blue-900">
            <FaNetworkWired className="w-12 h-12 text-blue-600 dark:text-blue-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'API Integration',
      description: 'Seamless connectivity with your existing systems through robust and flexible API solutions.',
      color: 'blue'
    },
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-green-400/20 dark:bg-green-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-green-200 dark:group-hover:shadow-green-900">
            <FaCheck className="w-12 h-12 text-green-600 dark:text-green-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'Order Confirmation',
      description: 'Real-time order tracking and instant confirmation to provide transparency and peace of mind.',
      color: 'green'
    },
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-purple-400/20 dark:bg-purple-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-purple-200 dark:group-hover:shadow-purple-900">
            <FaMapMarkerAlt className="w-12 h-12 text-purple-600 dark:text-purple-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'Fraud Detection',
      description: 'Advanced algorithmic protection to identify and prevent potential fraudulent activities.',
      color: 'purple'
    },
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-orange-400/20 dark:bg-orange-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-orange-200 dark:group-hover:shadow-orange-900">
            <FaShieldAlt className="w-12 h-12 text-orange-600 dark:text-orange-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'Security Protocols',
      description: 'Comprehensive security measures to protect your data and ensure compliance.',
      color: 'orange'
    },
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-indigo-400/20 dark:bg-indigo-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-indigo-200 dark:group-hover:shadow-indigo-900">
            <FaTruck className="w-12 h-12 text-indigo-600 dark:text-indigo-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'Logistics Tracking',
      description: 'End-to-end tracking of shipments with real-time updates and precise location monitoring.',
      color: 'indigo'
    },
    {
      icon: (
        <div className="relative inline-block group">
          <div className="absolute -inset-2 bg-teal-400/20 dark:bg-teal-400/10 blur-xl rounded-full group-hover:animate-ping"></div>
          <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-4 rounded-full shadow-2xl transition-all duration-300 group-hover:shadow-teal-200 dark:group-hover:shadow-teal-900">
            <FaDatabase className="w-12 h-12 text-teal-600 dark:text-teal-400 transform group-hover:scale-110 transition-transform" />
          </div>
        </div>
      ),
      title: 'Data Management',
      description: 'Intelligent data storage and management solutions for optimal logistics performance.',
      color: 'teal'
    }
  ];

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'} transition-colors duration-300`}>
      <div className="z-10">
        <NavBar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div 
            className="fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20 animate-slide-in"
            style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}
          >
            <Sidebar />
          </div>
        )}
        <div className="overflow-y-auto py-12 px-4 sm:px-6 lg:px-8 dark:bg-[rgba(1,14,78,0.96)] dark:text-white">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-12">
              <div className="flex justify-center mb-4">
                <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] p-6 rounded-full shadow-2xl animate-bounce-in">
                  <GrServices className="w-20 h-20 text-indigo-600 dark:text-indigo-400 transform hover:scale-110 transition-transform" />
                </div>
              </div>
              <h1 className="text-4xl font-extrabold dark:text-white sm:text-5xl text-indigo-600 animate-fade-in-up">
                Pickupxpress Logistics Services
              </h1>
              <p className="mt-4 text-xl text-gray-600 dark:text-white animate-fade-in-up delay-200">
                Comprehensive solutions to streamline your logistics operations
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 dark:bg-[rgba(1,14,78,0.96)] dark:text-white">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="bg-white shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)] rounded-custom text:white transform transition duration-300 hover:scale-105 hover:shadow-2xl animate-fade-in-up"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <div className="p-6 ">
                    <div className="flex items-center mb-4">
                      {service.icon}
                      <h2 className="ml-4 text-2xl font-bold text-gray-800 dark:text-white">
                        {service.title}
                      </h2>
                    </div>
                    <p className="text-gray-600 dark:text-white">
                      {service.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogisticsServicePage;