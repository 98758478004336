export const Base_Url = process.env.REACT_APP_BASE_URL;
 
export const Base_Url1 = process.env.REACT_APP_BASE_URL;

export const Url_For_View_Document = process.env.REACT_APP_VIEW_DOCUMENT_URL;

export const Addwarehouse='createwarehouse'

export const Warehouselist='warehouseslist'

export const priceEstimator='priceEstimator'

export const createShippment='createShipment'

export const reverseShipment = 'reverseShipment'

export const forwardorderlist ='forwardorderlist'

export const shipmentlabel = 'shipmentlabel'

export const  pickupRequest = 'pickupRequest'

export const track = 'track'

export const csvuplode = 'csvuplode'

export const  registerapi = 'register'

export const loginapi = 'login'

export const dashboard = 'dashboard'

export const getpincode = 'getpincode'

export  const deletewarehouse = 'deletewarehouse'

export const ndrList = 'ndrList'

export const  ndr = 'ndr'

export const  Reattempt = "Reattempt"

export const  Return = "Return"

export const pendingApi = "Pending";

export  const ndrStatusList = "ndrStatusList"

export  const uplodeDocument = "uplodeDocument"

export const userlist = "userlist";

export const userdocApprove = "userdocApprove"

export const userdata =  "userdata"

export const walletRecharge =  "wallet-recharge"

export const payment  =  "payment"

export const walletList = "walletList"

export const updateprofile = "updateprofile"

export const topUpList = "topUpList"

export const ratecsvuplode = "ratecsvuplode"

export const fetchUser = "fetchUser"

export const ratecardList = "ratecardList"

export const weightDispute = "weightDispute"

export const weightList  = "admin/weightList"

export const weightDisputesAllforUser = "weightList";

export const weightListByStatus  = "weightListStatus/Pending" ;

export const weightListStatusBylosed  = "weightListStatus/close" ;

export const weightListStatusByAccept  = "weightListStatus/Accept";

export const dispute_action  = "dispute_action" 

export const weightListStatusByRise  = "weightListStatus/Rise";

export const dispute_action_admin  = "admin/dispute_action";

export const forgetpassSendOtp = "forgetpassSendOtp";

export  const verifyOtp = "forgetpassVerifyOtp";

export const updatePassword = "forgetpassChangePassword"

export const editwarehouse = "editwarehouse"

export const allwarehouseslist = "allwarehouseslist"

export const forwardorderlistall ='forwardorderlistall'

export const accountUpdaterequest = "accountUpdaterequest";