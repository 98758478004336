import React, { useEffect, useState } from 'react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { FaUser } from "react-icons/fa";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Button } from '@mui/material';
import { IoMdEye } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { Base_Url, userlist } from '../../config/config';
import toast from 'react-hot-toast';
import '../../../src/css/custom-scrollbar.css'
import { CiEdit } from "react-icons/ci";
import { AiOutlineDownload } from "react-icons/ai";
import ViewAllCustomerModel from '../../component/models/ViewAllCustomerModel';

const ViewAllCustomer = () => {
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [customers, setCustomers] = useState([]);
  const [totalCustomer, setTotalCustomers] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [allCustomers, setAllCustomers] = useState([]);

  useEffect(() => {
    fetchCustomers();
  }, [currentPage, limit]);

  const handleViewClick = (customer) => {
    setSelectedCustomer(customer);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleDownloadCSV = (customer) => {
    const csvContent = `data:text/csv;charset=utf-8,${[
        ["ID", "Name", "Email", "Phone", "Company Name", "Company Type", "GST No", "Pancard No", "Aadharcard No"],
        [customer.id,
        customer.name,
        customer.email,
        customer.phone,
        customer.company_name,
        customer.company_type,
        customer.gst_number,
        customer.pancard_number,
        customer.aadhar_number
        ]
      ]
        .map((row) => row.join(","))
        .join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `customer_${customer.id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAllCustomers = () => {
    const csvContent = [
      ["ID", "Name", "Email", "Phone", "Company Name", "Company Type", "GST No", "Pancard No", "Aadharcard No"],
      ...customers.map(customer => [
        customer.id,
        customer.name,
        customer.email,
        customer.phone,
        customer.company_name,
        customer.company_type,
        customer.gst_number,
        customer.pancard_number,
        customer.aadhar_number
      ])
    ]
      .map(row => row.join(","))
      .join("\n");

    if (customers.length === 0) {
      toast.error("No customers available to download");
      return;
    }

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "all_customers.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchCustomers = async () => {
    const url = `${Base_Url}admin/${userlist}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        const filteredCustomers = data.data.filter(customer => customer.role !== 'admin');
        const adminCount = data.data.filter(customer => customer.role === 'admin').length;
        const adjustedTotal = data.pagination.totalItems - adminCount;

        setCustomers(filteredCustomers);
        setTotalCustomers(adjustedTotal || 0);
        // // Fetch all customers if not already fetched
        // if (allCustomers.length === 0) {
        //   await fetchAllCustomers();
        // }
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePaginationChange = (e, value) => {
    setCurrentPage(value);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows([...Array(customers.length).keys()]);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };

  const handleRowSelect = (index) => {
    setSelectedRows((prev) => {
      const isSelected = prev.includes(index);
      if (isSelected) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: '113px', height: 'calc(100vh - 115px)', overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}

        <div className="w-full dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4 md:ml-4 md:mt-4 md:mb-8 md:mr-4 custom-scrollbar overflow-x-hidden overflow-y-auto">
          {/* <div
            className={`shadow-custom-light w-full rounded-custom  dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''
              }`}
          > */}
            <div className={`shadow-custom-light mt-6 mb-6 mr-6 w-full rounded-custom dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>
              <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                <button
                  className="text-xl w-full sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px]  sm:w-full flex items-center justify-center">
                  <FaUser className="mr-2" size={24} />
                  All Customers
                </button>
                {selectAll && (

                  <div className='w-full sm:w-auto'>
                    <button
                      onClick={handleDownloadAllCustomers}
                      className="mb-4 sm:mb-6 w-full sm:ml-2 bg-green-500 h-[40px] hover:bg-green-600 text-white px-4 py-4 sm:px-8 sm:py-3 rounded flex items-center whitespace-nowrap"
                    >
                      <AiOutlineDownload className="mr-2" size={24} />
                      Download In Bulk
                    </button>
                  </div>

                )}
              </div>



              <div className='overflow-x-auto'>
                <table className="min-w-full w-full table-auto">
                  <thead className="bg-[#0540a6dd] text-white text-[14px]">
                    <tr className='bg-[#0540a6dd] text-white border border-[#aba7a7]'>
                      <th className='p-2 text-left'>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="p-2 text-left w-1/12">ID</th>
                      <th className="p-2 text-left w-2/12">Name</th>
                      <th className="p-2 text-left w-3/12">Email</th>
                      <th className="p-2 text-left w-2/12">Phone</th>
                      <th className="p-2 text-left w-2/12 pl-12">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => (
                      <tr key={customer.id} className="w-full">
                        <td className='p-2'>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </td>
                        <td className="p-2">{customer.id}</td>
                        <td className="p-2">{customer.name}</td>
                        <td className="p-2">{customer.email}</td>
                        <td className="p-2">{customer.phone}</td>
                        <td className="p-2">
                          <div className="flex space-x-2">
                            <Button variant="contained" size="small" color="primary"
                              onClick={() => handleViewClick(customer)}
                            >
                              <IoMdEye />
                            </Button>
                            <Button variant="contained" size="small" color="error">
                              <MdDelete />
                            </Button>
                            <Button variant="contained" size="small" color="secondary"
                              onClick={() => handleDownloadCSV(customer)}>
                              <AiOutlineDownload />
                            </Button>
                            {/* <Button variant="contained" size="small" color="primary">
                              <CiEdit/>
                            </Button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
          </div>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
            <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total customer: ${totalCustomer}`}</span>
            <select
              id="limit"
              value={limit}
              onChange={handleLimitChange}
              className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={totalCustomer}>All</option>
            </select>
            <Pagination
              count={Math.ceil(totalCustomer / limit)}
              page={currentPage}
              onChange={handlePaginationChange}
              variant="outlined"
              shape="rounded"
              className="pagination"
              size="small"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                />
              )}
            />
          </div>
        </div>
        <ViewAllCustomerModel isopen={isPopupOpen} onClose={handleClose} customer={selectedCustomer} />
      </div>
    </div>
  );
};

export default ViewAllCustomer;