import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { z } from 'zod';
import { FaWeight } from 'react-icons/fa'
import { useForm } from 'react-hook-form';
import { IoIosCloseCircle } from "react-icons/io";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { FaEye } from "react-icons/fa";
import { Url_For_View_Document } from '../../config/config';

const schema = z.object({
  id: z.string().min(1, "ID is required"),
  seller_id: z.string().min(1, "Seller ID is required"),
  waybill: z.string().min(1, "Waybill is required"),
  initial_weight: z.string().min(1, "Initial Weight is required"),
  dimension: z.string().min(1, "Dimension is required"),
  product_des: z.string().min(1, "Product Description is required"),
  sku: z.string().min(1, "SKU is required"),
  date: z.string().min(1, "Date is required"),
  reversed_weight: z.string().min(1, "Reversed Weight is required"),
  price: z.string().min(1, "Price is required"),
  status: z.string().min(1, "Status is required"),
  timestamp: z.string().min(1, "Timestamp is required"),
});

const ViewWeightDisputes = ({ isopen, onClose, allWeightDisputes }) => {
  const { isDarkMode } = useMyContext();
  const [imageUrls, setImageUrls] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(schema),
  });


  useEffect(() => {
    if (allWeightDisputes) {
      reset({
        id: allWeightDisputes.id || '',
        seller_id: allWeightDisputes.seller_id || '',
        waybill: allWeightDisputes.waybill || '',
        initial_weight: allWeightDisputes.initial_weight || '',
        dimension: allWeightDisputes.dimension || '',
        product_des: allWeightDisputes.product_des || '',
        sku: allWeightDisputes.sku || '',
        date: allWeightDisputes.date || '',
        reversed_weight: allWeightDisputes.reversed_weight || '',
        price: allWeightDisputes.price || '',
        status: allWeightDisputes.status || '',
        timestamp: allWeightDisputes.timestamp || '',
      });

      const images = JSON.parse(allWeightDisputes.image || '{}').images || [];
      setImageUrls(images);
    }
  }, [allWeightDisputes, reset]);

  const onSubmit = (data) => {
    onClose();
  };

  const handleClose = () => {
    setIsEditMode(false);
    onClose();
  };

  const handleEyeClick = (url) => {
    const viewDocumentUrl = Url_For_View_Document + url;
    window.open(viewDocumentUrl, "_blank");
  }

  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-customPurple text-white relative  shadow-custom-light dark:shadow-custom-dark ">
        <h2
          className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaWeight className="mr-2" size={24} />
          All Weight Disputes Details
        </h2>
        <IoIosCloseCircle
          className="absolute  top-0 right-0 cursor-pointer"
          size={32}
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
        <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom-custom">

          <form className="space-y-6 font-sans" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">ID</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('id')}
                  disabled={!isEditMode}
                />
                {errors.id && <p className="text-red-500 text-sm">{errors.id.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">seller_id</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('seller_id')}
                  disabled={!isEditMode}
                />
                {errors.seller_id && <p className="text-red-500 text-sm">{errors.seller_id.message}</p>}
              </div>
            </div>

            {/* Additional form fields following the same structure */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Waybill</label>
                <input
                  type="waybill"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('waybill')}
                  disabled={!isEditMode}
                />
                {errors.waybill && <p className="text-red-500 text-sm">{errors.waybill.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Initial_Weight</label>
                <input
                  type="initial_weight"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('initial_weight')}
                  disabled={!isEditMode}
                />
                {errors.initial_weight && <p className="text-red-500 text-sm">{errors.initial_weight.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Dimension</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('dimension')}
                  disabled={!isEditMode}
                />
                {errors.dimension && <p className="text-red-500 text-sm">{errors.dimension.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Product_Des</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('product_des')}
                  disabled={!isEditMode}
                />
                {errors.product_des && <p className="text-red-500 text-sm">{errors.product_des.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">SKU</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('sku')}
                  disabled={!isEditMode}
                />
                {errors.sku && <p className="text-red-500 text-sm">{errors.sku.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Revirsed_Weight</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('reversed_weight')}
                  disabled={!isEditMode}
                />
                {errors.reversed_weight && <p className="text-red-500 text-sm">{errors.reversed_weight.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Price</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('price')}
                  disabled={!isEditMode}
                />
                {errors.price && <p className="text-red-500 text-sm">{errors.price.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Date</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('date')}
                  disabled={!isEditMode}
                />
                {errors.date && <p className="text-red-500 text-sm">{errors.date.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Status</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('status')}
                  disabled={!isEditMode}
                />
                {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Timestamp</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('timestamp')}
                  disabled={!isEditMode}
                />  
                {errors.timestamp && <p className="text-red-500 text-sm">{errors.timestamp.message}</p>}
              </div>
            </div>
             
            <div>
              <label className="block text-sm ml-2 font-bold text-black dark:text-white">
                Image For Dimension : 
              </label>
              {imageUrls.map((url, index) => (
                <div key={index} className="flex items-center mt-2">
                  <input
                    type="text"
                    value={url}
                    className={`p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""
                      }`}
                    disabled
                  />
                  <button
                    type="button"
                    onClick={() => handleEyeClick(url)}
                    className="ml-2 text-gray-600 dark:text-gray-400 hover:text-blue-500"
                    aria-label={`View image ${index + 1}`}
                  >
                    <FaEye size={24} />
                  </button>
                </div>
              ))}
            </div>

            {isEditMode && (
              <button
                type="submit"
                className="bg-customPurple text-white py-2 px-4 md:ml-8 rounded-custom-custom mt-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
              >
                Save
              </button>
            )}
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewWeightDisputes;
