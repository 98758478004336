import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircle } from "react-icons/io";
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { FaUser,FaUniversity  } from "react-icons/fa";
import { Base_Url, Url_For_View_Document, accountUpdaterequest } from '../../config/config';
import { FaEye } from 'react-icons/fa'
import axios from 'axios';
import toast from 'react-hot-toast';

const ViewAllCustomer = ({ isopen, onClose, customer }) => {
  const { isDarkMode } = useMyContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [cancelledChequeFile, setCancelledChequeFile] = useState(null);
  const [mailScreenshotFile, setMailScreenshotFile] = useState(null);
  
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onErrors = (errors) => {
    console.log("Validation Errors:", errors);
    toast.error("Please fix the errors in the form.");
  };

  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');

  useEffect(() => {
    if (customer) {
      reset({
        Id: customer.id || '',
        Name: customer.name || '',
        email: customer.email || '',
        phone: customer.phone || '',
        company_name: customer.company_name || '',
        company_type: customer.company_type || '',
        status: customer.status || '0',
        profile_flag: customer.profile_flag || '0',
        user_address_image: customer.user_address_image || '',
        user_pancard_image: customer.user_pancard_image || '',
        user_gst_image: customer.user_gst_image || '',
        aadhar_status: customer.aadhar_status || '0',
        pan_card_status: customer.pan_card_status || '0',
        gst_status: customer.gst_status || '0',
        aadhar_number: customer.aadhar_number || '',
        pancard_number: customer.pancard_number || '',
        gst_number: customer.gst_number || '',
        role: customer.role || '',
        txn_amount: customer.txn_amount || '',
        wallet_status: customer.wallet_status || '0',
        create_date: customer.create_date || '',
        timestamp: customer.timestamp || '',
        account_holder_name: customer.account_holder_name || '',
        account_no: customer.account_no || '',
        bank_name: customer.bank_name || '',
        ifsc_code: customer.ifsc_code || '',
      });
      setCancelledChequeFile(null);
      setMailScreenshotFile(null);
    }
  }, [customer, reset]);

  const handleFileChange = (event, setFileFunction) => {
    const file = event.target.files[0];
    if (file) {
      setFileFunction(file);
    }
  };

  const ViewDocument = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleClose = () => {
    setIsEditMode(false);
    setCancelledChequeFile(null);
    setMailScreenshotFile(null);
    onClose();
  };

  const onSubmit = async (data) => {
  const formData = new FormData();
    
    formData.append('user_id', customer.id || '');
    formData.append('bank_name', data.bank_name || '');
    formData.append('ifsc_code', data.ifsc_code || '');
    formData.append('account_holder', data.account_holder_name || '');
    formData.append('account_no', data.account_no || '');

    if (cancelledChequeFile) {
      formData.append('cancel_check', cancelledChequeFile);
    }
    
    if (mailScreenshotFile) {
      formData.append('mail_screenshort', mailScreenshotFile);
    }

    try {
      const response = await axios.post(Base_Url + accountUpdaterequest, formData, {
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === "SUCCESS") {
        toast.success(response.data.message);
        handleClose();
      } else {
        toast.error(response.data.message.cancel_check);
        toast.error(response.data.message.mail_screenshort || '');
      }
    } catch (error) {
      console.error('API Error:', error);
      toast.error('Failed to update account details');
    }
  };

  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle className="bg-customPurple text-white relative shadow-custom-light dark:shadow-custom-dark">
        <h2 className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaUser className="mr-2" size={24} />
          customer Details
        </h2>
        <IoIosCloseCircle
          className="absolute top-2 right-2 cursor-pointer"
          size={32}
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
        <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom">
          <form className="space-y-6 font-sans" onSubmit={handleSubmit(onSubmit, onErrors)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">ID</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Id')}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Name</label>
                <input
                  type="text"
                  {...register('Name')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Contact Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Email</label>
                <input
                  type="email"
                  {...register('email')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Phone</label>
                <input
                  type="tel"
                  {...register('phone')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Company Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Company Name</label>
                <input
                  type="text"
                  {...register('company_name')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Company Type</label>
                <input
                  type="text"
                  {...register('company_type')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Status Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Profile</label>
                <select
                  {...register('profile_flag')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                >
                  <option value="0">User Unverified</option>
                  <option value="1">User Varified</option>
                </select>
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">GST No</label>
                <input
                  type="text"
                  {...register('gst_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Document Numbers */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Aadhar Number</label>
                <input
                  type="text"
                  {...register('aadhar_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">PAN Card Number</label>
                <input
                  type="text"
                  {...register('pancard_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">Aadhar Card Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_address_image)}
              >
                <FaEye size={20} />
              </button>
            </div>

            <input
              type="text"
              {...register('user_address_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />
            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">Pan Card Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_pancard_image)}
              >
                <FaEye size={20} />
              </button>
            </div>
            <input
              type="text"
              {...register('user_pancard_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />
             

            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">GST Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_gst_image)}
              >
                <FaEye size={20} />
              </button>
            </div>
            <input
              type="text"
              {...register('user_gst_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />
                    <div className="mt-8 p-4 bg-blue-50 dark:bg-[rgba(1,20,100,0.96)] rounded-lg border-2 border-blue-200 dark:border-blue-900">
              <div className="flex items-center mb-4">
                <FaUniversity className="text-blue-600 dark:text-blue-400 mr-2" size={24} />
                <h3 className="text-lg font-bold text-blue-800 dark:text-blue-300">Bank Account Details</h3>
              </div>
              
              <p className="text-sm text-blue-600 dark:text-blue-300 mb-4">
                Please ensure all bank details are accurate. These details will be used for processing payments and transactions.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm ml-2 font-bold text-blue-800 dark:text-blue-300">Account Holder Name</label>
                  <input
                    type="text"
                    {...register('account_holder_name')}
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    disabled={!isEditMode}
                  />
                </div>
                <div>
                  <label className="block text-sm ml-2 font-bold text-blue-800 dark:text-blue-300">Bank Name</label>
                  <input
                    type="text"
                    {...register('bank_name')}
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    disabled={!isEditMode}
                  />
                </div>
                <div>
                  <label className="block text-sm ml-2 font-bold text-blue-800 dark:text-blue-300">Account Number</label>
                  <input
                    type="text"
                    {...register('account_no')}
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    disabled={!isEditMode}
                  />
                </div>
                <div>
                  <label className="block text-sm ml-2 font-bold text-blue-800 dark:text-blue-300">IFSC Code</label>
                  <input
                    type="text"
                    {...register('ifsc_code')}
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    disabled={!isEditMode}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 mt-4">
                <div>
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold text-blue-800 dark:text-blue-300 flex-1">
                      Cancelled Cheque
                    </label>
                    {customer?.cancel_check_image && (
                      <button
                        type="button"
                        className="ml-2 text-blue-600 hover:text-blue-700 font-semibold"
                        onClick={() => ViewDocument(Url_For_View_Document + customer.cancel_check_image)}
                      >
                        <FaEye size={20} />
                      </button>
                    )}
                  </div>
                  {isEditMode ? (
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setCancelledChequeFile)}
                      className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 
                      ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : ""}`}
                    />
                  ) : (
                    <div className={`mt-2 p-2 border rounded-custom 
                      ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : ""}`}>
                      {customer?.cancel_check_image || 'No file uploaded'}
                    </div>
                  )}
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-bold text-blue-800 dark:text-blue-300 flex-1">
                      Bank Statement/Proof
                    </label>
                    {customer?.account_appruve_mail && (
                      <button
                        type="button"
                        className="ml-2 text-blue-600 hover:text-blue-700 font-semibold"
                        onClick={() => ViewDocument(Url_For_View_Document + customer.account_appruve_mail)}
                      >
                        <FaEye size={20} />
                      </button>
                    )}
                  </div>
                  {isEditMode ? (
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, setMailScreenshotFile)}
                      className={`mt-2 p-2 border w-full rounded-custom focus:ring-2 focus:ring-blue-400 
                      ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : ""}`}
                    />
                  ) : (
                    <div className={`mt-2 p-2 border rounded-custom 
                      ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : ""}`}>
                      {customer?.account_appruve_mail || 'No file uploaded'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-start gap-4">
              <button
                onClick={() => setIsEditMode(!isEditMode)}
                className="bg-customPurple text-white py-2 px-4 rounded-custom hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                type="button"
              >
                {isEditMode ? 'Cancel' : 'Edit'}
              </button>

              {isEditMode && (
                <button
                  type="submit"
                  className="bg-customPurple text-white py-2 px-4 rounded-custom hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                >
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewAllCustomer;
