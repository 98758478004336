

import React, { useState } from 'react';
import { AlertCircle, ArrowLeft, Mail, Lock } from 'lucide-react';
import { forgetpassSendOtp, Base_Url } from '../../../config/config';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const OtpverifyPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate   = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const requestBody = {
      email: email,
    };
  
    try {
      const response = await axios.post(Base_Url + forgetpassSendOtp, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
  
      if (response.data.status === 'success') {
        toast.success(response.data.message);
        setSubmitted(true);
      } else if (response.data.status === 'fail') {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Log error to debug if needed
      console.error(error);
  
      // Check if error.response exists and contains a message
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 flex items-center justify-center p-4">
      <div className="w-full min-h-[600px] bg-white rounded-2xl shadow-xl overflow-hidden flex">
        {/* Left side content with video */}
        <div className="flex-1 relative hidden lg:block overflow-hidden">
          <video
            className="absolute inset-0 w-full h-full object-cover"
            src="/assets/video/161169-822846927.mp4"
            autoPlay
            loop
            muted
          />
          <div className="absolute inset-0 bg-gradient-to-br from-customPurple to-purple-700 opacity-75"></div>
          <div className="relative z-10 h-full flex flex-col p-12 text-white">
            <h1 className="text-4xl font-bold mb-6">Reset Your Password</h1>
            <p className="text-lg text-blue-100 mb-8">
              Don't worry, it happens to the best of us. Enter your email and we'll help you get back on track.
            </p>
            {/* Optional security notes */}
            <div className="mt-auto">
              <div className="flex items-center space-x-2 text-sm text-blue-100">
                <Lock className="w-4 h-4" />
                <span>Secure 256-bit encrypted connection</span>
              </div>
            </div>
          </div>
        </div>
  
        {/* Right side form */}
        <div className="w-full lg:w-[450px] p-8 lg:p-12">
          <div className="mb-8">
            <img className="mx-auto" src="/assets/images/logo1.png" width="200" height="150" alt="Logo" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Password Recovery</h2>
            <p className="text-gray-600">Get back to your account in no time</p>
          </div>
  
          {!submitted ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Email Address
                </label>
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-11 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                    placeholder="Enter your email"
                    required
                  />
                  <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors relative"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin" />
                    <span className="ml-2">Sending...</span>
                  </div>
                ) : (
                  'Send Reset Link'
                )}
              </button>

              <div className="p-4 bg-blue-50 rounded-lg">
                <div className="flex items-start space-x-2 text-sm">
                  <AlertCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                  <div className="text-gray-600">
                    <strong className="font-medium text-gray-900">Pro tip:</strong>
                    <br />
                    Check your spam folder if you don't see our email within a few minutes.
                  </div>
                </div>
              </div>
  
              <div className="flex items-center justify-between pt-4">
                <a href="/login" className="flex items-center text-sm text-gray-600 hover:text-gray-900 transition-colors">
                  <ArrowLeft className="w-4 h-4 mr-1" />
                  Back to Login
                </a>
                <a href="/register" className="text-blue-600 hover:text-blue-700 font-medium text-sm">
                  Create Account
                </a>
              </div>
            </form>
          ) : (
            <div className="text-center space-y-6">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Check Your Email</h3>
                <p className="text-gray-600 mb-6">
                  We've sent password reset instructions to <strong>{email}</strong>. Please check your inbox.
                </p>
                <div className="space-y-4">
                  <button
                    onClick={() => setSubmitted(false)}
                    className="text-blue-600 hover:text-blue-700 font-medium block w-full"
                  >
                    Try another email
                  </button>
                  <a href="/login" className="text-gray-600 hover:text-gray-900 block w-full">
                    Return to login
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpverifyPassword;
