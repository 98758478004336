import React, { useState, useEffect } from 'react';
import { IoMdEye } from 'react-icons/io';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ViewWeightDisputes from '../../../component/models/viewWeightDisputes';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import PaginationItem from '@mui/material/PaginationItem';
import '../../../css/custom-scrollbar.css';
import { FaWeight } from 'react-icons/fa'
import { MdCheckCircle, MdCancel } from "react-icons/md";
import axios from 'axios';
import toast from 'react-hot-toast';
import { dispute_action_admin, Base_Url, weightList } from '../../../config/config';


const AllWeightDisputes = () => {
    const [totalOrders, setTotalOrders] = useState(0);
    const { isDarkMode } = useMyContext();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [selectAll, setSelectAll] = useState(false);
    const { isToggleSidebar } = useMyContext();
    const [weights, setweights] = useState([]);
    const [selectedweight, setSelectedweight] = useState(null);
    const navigate = useNavigate();


    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        fetchAllDisputes()
    }, [page, limit]);


    const handleAccept = async (row) => {
        const token = localStorage.getItem('token');
        const cus_id = localStorage.getItem('cus_id');
        const formData = new FormData();
        formData.append("waybill", row.waybill);
        formData.append("status", "Accept");
        formData.append("seller_id", row.seller_id);

        try {
            const response = await axios.post(Base_Url + dispute_action_admin, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': token,
                    'cus_id': cus_id,
                },
            });
            if (response.data.status === "SUCCESS") {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Error uploading data: " + error.message);
        }
    };
    const handleReject = async (row) => {
        const token = localStorage.getItem('token');
        const cus_id = localStorage.getItem('cus_id');

        const formData = new FormData();
        formData.append("waybill", row.waybill);
        formData.append("status", "Raise");
        formData.append("seller_id", row.seller_id);

        try {
            const response = await axios.post(Base_Url + dispute_action_admin, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': token,
                    'cus_id': cus_id,
                },
            });
            if (response.data.status === "SUCCESS") {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Error uploading data: " + error.message);
        }
    };

    const fetchAllDisputes = async () => {
        const token = localStorage.getItem('token');
        const cusid = localStorage.getItem('cus_id');
        try {
            const pageNumber = Number(page) || 1;
            const limitNumber = Number(limit) || 10;
            const urlweight = `${Base_Url}${weightList}?page=${pageNumber}&limit=${limitNumber}`;
            const response = await fetch(urlweight, {
                method: 'GET',
                headers: {
                    token,
                    cus_id: cusid,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status === "SUCCESS") {
                setweights(result.data);
                setTotalOrders(result.pagination.total_records);
            } else {
                toast.error(result.message || 'Failed to fetch weight disputes.');
            }
        } catch (error) {
            console.error('Error fetching weight data:', error);
            toast.error('Something went wrong!');
        }
    };


    const handlePaginationChange = (event, value) => {
        setPage(value);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(1);
    };

    const handleViewClick = (weight) => {
        setSelectedweight(weight);
        setIsPopupOpen(true);
    };

    const handleClose = () => {
        setIsPopupOpen(false);
    };

    const handleNavigation = () => {
        navigate('/dashboard');
    };

    return (
        <>
            <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
                <div className="z-10">
                    <NavBar />
                </div>

                <div className="flex flex-1 overflow-hidden">
                    {isToggleSidebar && (
                        <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                            style={
                                window.innerWidth < 768
                                    ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                                    : {}
                            }>
                            <Sidebar />
                        </div>
                    )}

                    <div className={`shadow-custom-light overflow-x-hidden ml-4 custom-scrollbar mt-6 mb-6 mr-6 w-full rounded-custom overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>

                        <button onClick={handleNavigation}
                            className="text-xl w-full sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px]  sm:w-full flex items-center justify-center">
                            <FaWeight className="mr-2" size={24} />
                            All Weight Disputes
                        </button>

                        <div className="overflow-x-auto custom-scrollbar">
                            <table className="min-w-full w-full table-auto">
                                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                                    <tr className="bg-[#0540a6dd] text-white border-b border-[#aba7a7]">
                                        <th className="p-4 text-left">SellerId</th>
                                        <th className="p-4 text-left">Waybill</th>
                                        <th className="p-4 text-left">Initial Weight</th>
                                        <th className="p-4 text-left">Dimension</th>
                                        <th className="p-4 text-left">Product Description</th>
                                        <th className="p-4 text-left">SKU</th>
                                        <th className="p-4 text-left">Reversed Weight</th>
                                        <th className="p-4 text-left">Price</th>
                                        <th className="p-4 text-left">Status</th>
                                        <th className="p-4 text-left">Date</th>
                                        <th className="p-4 text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {weights.map((weight, index) => (
                                        <tr key={weight.id} className="border-b">
                                            <td className="p-4">{weight.seller_id}</td>
                                            <td className="p-4">{weight.waybill}</td>
                                            <td className="p-4">{weight.initial_weight}</td>
                                            <td className="p-4">{weight.dimension}</td>
                                            <td className="p-4">{weight.product_des}</td>
                                            <td className="p-4">{weight.sku}</td>
                                            <td className="p-4">{weight.reversed_weight}</td>
                                            <td className="p-4">{weight.price}</td>
                                            <td className="p-4">{weight.status}</td>
                                            <td className="p-4">{weight.date}</td>
                                            <td className="p-4">
                                                <div className="flex space-x-2">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => handleViewClick(weight)}
                                                    >
                                                        <IoMdEye />
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="success"
                                                        onClick={() => handleAccept(weight)}
                                                        disabled={weight.status === "close"}
                                                    >
                                                        <MdCheckCircle />
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="error"
                                                        onClick={() => handleReject(weight)}
                                                        disabled={weight.status === "close"}
                                                    >
                                                        <MdCancel />
                                                    </Button>
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                            <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Weight: ${totalOrders}`}</span>
                            <select
                                id="limit"
                                value={limit}
                                onChange={handleLimitChange}
                                className="border rounded p-1 sm:ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                            >
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                {/* <option value={50}>50</option> */}
                                <option value={totalOrders}>All</option>
                            </select>
                            <Pagination
                                count={Math.ceil(totalOrders / limit)}
                                page={page}
                                onChange={handlePaginationChange}
                                variant="outlined"
                                shape="rounded"
                                className="pagination"
                                size="small"
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <ViewWeightDisputes isopen={isPopupOpen} onClose={handleClose} allWeightDisputes={selectedweight} />
            </div>
        </>
    );
};
export default AllWeightDisputes;
