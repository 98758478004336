import React, { useState, useEffect } from 'react';
import { Download } from 'lucide-react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import { useNavigate } from 'react-router-dom';
import { Base_Url, forwardorderlistall } from '../../config/config';

const ReportsDropdown = () => {
    const { isDarkMode, isToggleSidebar } = useMyContext();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');

    useEffect(() => {
        fetchOrderData();
    }, []);

    const fetchOrderData = async () => {
        try {
            const response = await fetch(Base_Url + forwardorderlistall, {
                method: 'GET',
                headers: {
                    token,
                    cus_id: cusid,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                setData(result.data || []);
            } else {
                console.error('Error fetching data: ' + result.message);
            }
        } catch (error) {
            console.error('API error: ' + error.message);
        }
    };

    const handleNavigate = () => {
        navigate("/dashboard");
    }

    const downloadCSV = (report) => {
        const csvRows = [];
        csvRows.push(report.columns.join(','));
        data.forEach(item => {
            const row = report.columns.map(column => {
                // Adjust key mapping based on your actual data structure
                return item[column.toLowerCase().replace(' ', '_')] || 'N/A'; // Use N/A for missing data
            });
            csvRows.push(row.join(','));
        });

        // Create a Blob from the CSV data
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Create a link to download the CSV
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${report.name}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    const reports = [
        {
            name: 'MIS Report',
            columns: [
                'Order ID',
                'First Name',
                'Last Name',
                'Address',
                'City',
                'State',
                'Country',
                'Phone',
                'Email',
                'Payment Mode',
                'Total Amount',
                'Order Date',
                'Is NDR',
                'Waybill',
                'warehouse_id',
                'shipment_type',
                'payment_id',
                'order',
                'return_pin',
                'return_city',
                'return_phone',
                'return_address',
                'return_state',
                'return_country',
                'products_desc',
                'hsn_code',
                'cod_amount',
                'quantity',
                'shipment_width',
                'shipment_height',
                'weight',
                'seller_gst_tin',
                'shipping_mode',
                'address_type',
                'pickup_name',
                'pickup_address',
                'pickup_city',
                'pickup_pin_code',
                'pickup_country',
                'pickup_phone',
                'request_id',
                'ndr_count',
                // 'label',
                'ready_to_shipment',
                'length',
                'partner'
            ]
        },
        // { name: 'Orders Report', columns: ['Order Status', 'Total Orders'] },
        { name: 'Order Product Report', columns: ['Order_id', 'First Name', 'Last Name', 'Product Name', 'Quantity', 'SkU', 'Awb No', 'Date'] },
        { name: 'Current Shipment Report', columns: ['Order_id', 'First Name', 'Last Name', 'Product Name', 'Quantity', 'SkU', 'Awb No', 'Address', 'City', 'State', 'Pincode', 'Current Status'] },
        { name: 'NDR Report', columns: ['Order_id', 'First Name', 'Last Name', 'Contact No', 'Product Name', 'Awb No', 'Address', 'Pincode', 'City', 'State', 'Status', 'Attempt', 'last Update', 'Reason'] },
        // { name: 'Product-wise Report', columns: ['Product Name', 'SKU', 'Total', 'Transit', 'Delivered', 'Undelivered', 'RTO', 'RTO Delivered', 'Others'] },
        // { name: 'View Reports', columns: ['Report Date', 'Report Title', 'Report Type', 'Generated By', 'Status'] },
    ];
    return (
        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768
                                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                                : {}
                        }>
                        <Sidebar />
                    </div>
                )}
                <div className="container mx-auto p-6 bg-white rounded-custom shadow-custom-light dark:shadow-custom-dark max-w-full dark:bg-[rgba(1,14,78,0.96)] font-sans md:ml-4 md:mt-4 md:mb-8 md:mr-4 custom-scrollbar overflow-y-auto">
                    <button onClick={handleNavigate}
                        className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-4 py-2 rounded-custom shadow-md w-full">
                        Reports Dashboard
                    </button>
                    <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {reports.map((report) => (
                            <div key={report.name} className={`bg-white shadow-custom-light rounded-custom p-4 mb-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]  dark:text-white dark:shadow-custom-dark ' : 'bg-white'}`}>
                                <div className="flex items-center justify-between mb-2">
                                    <h3 className="text-lg font-bold">{report.name}</h3>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        size="small"
                                        className="capitalize bg-blue-500 hover:bg-blue-600 text-white"
                                        // onClick={() => downloadCSV(reports)}
                                        onClick={() => downloadCSV(report)}
                                    >
                                        <Download size={20} />
                                    </Button>
                                </div>
                                <div className="overflow-x-auto overflow-hidden">
                                    <table className="min-w-full table-auto border-collapse">
                                        <thead className="bg-[#0540a6dd] text-white text-sm">
                                            <tr>
                                                {report.columns.map((column) => (
                                                    <th
                                                        key={column}
                                                        className="p-2 text-left text-xs font-medium uppercase tracking-wider border-b border-gray-300"
                                                    >
                                                        {column}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {report.name === 'MIS Report' && data.length > 0 ? (
                                                <tr key={data[0].id}>
                                                    {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].id}</td> */}
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].order_id}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].last_name}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].address}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].city}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].state}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].country}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].phone}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].email}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].payment_mode}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].total_amount}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].order_date}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].is_ndr}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].warehouse_id}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].shipment_type}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].payment_id}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_pin}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_city}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_phone}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_address}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_state}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].return_country}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].products_desc}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].hsn_code}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].cod_amount}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].seller_address}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].seller_name}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].seller_inv}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].quantity}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].shipment_width}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].shipment_height}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].weight * 1000}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].seller_gst_tin}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].address_type}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_name}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_address}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_city}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_pin_code}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_country}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pickup_phone}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].request_id}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].ndr_count}</td>
                                                    {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].label}</td> */}
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].ready_to_shipment}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].length}</td>
                                                    <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].partner}</td>
                                                </tr>
                                            ) :
                                                // (
                                                //     <tr>
                                                //         <td colSpan={report.columns.length} className="p-2 text-center text-sm text-gray-500">
                                                //             No data available
                                                //         </td>
                                                //     </tr>
                                                // )}
                                                (
                                                    <>
                                                        {report.name === 'Order Product Report' && data.length > 0 ? (
                                                            <tr key={data[0].id}>
                                                                {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].id}</td> */}
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].order_id}</td>
                                                                {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name} {data[0].last_name} </td> */}
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].last_name}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].products_desc}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].quantity}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].sku}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].waybill}</td>
                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].created_at}</td>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {report.name === 'Current Shipment Report' && data.length > 0 ? (
                                                                    <tr key={data[0].id}>
                                                                        {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].id}</td> */}
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].order_id}</td>
                                                                        {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name + data[0].last_name}</td>
                                                           */}
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].last_name}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].products_desc}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].quantity}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].sku}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].waybill}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].address}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].city}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].state}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pincode}</td>
                                                                        <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].status}</td>

                                                                    </tr>
                                                                ) : (
                                                                    <>
                                                                        {report.name === 'NDR Report' && data.length > 0 ? (
                                                                            <tr key={data[0].id}>
                                                                                {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].id}</td> */}
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].order_id}</td>
                                                                                {/* <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name + data[0].last_name}</td> */}
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].first_name}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].last_name}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].phone}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].products_desc}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].waybill}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].address}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].pincode}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].city}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].state}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].Status}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].attempt}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].last_update_date}</td>
                                                                                <td className="p-2 whitespace-nowrap text-sm text-gray-500">{data[0].reason}</td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan={report.columns.length} className="p-2 text-center text-sm text-gray-500">
                                                                                    No data available
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                        }
                                                                    </>
                                                                )
                                                                }
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportsDropdown;
