import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoIosCloseCircle } from "react-icons/io";
import { FiUploadCloud, FiX } from "react-icons/fi";
import axios from 'axios';
import { Base_Url, dispute_action } from '../../config/config';
import toast from 'react-hot-toast';
import '../css/custom-scrollbar.css';
import { useMyContext } from '../../context/MyContext';

const StatusRaised = ({ isopen, onClose, waybill_no }) => {
    const { isDarkMode } = useMyContext();
    const [images, setImages] = useState([]);
    const [measurements, setMeasurements] = useState({
        length: '',
        width: '',
        height: '',
        weight: ''
    });

    const handleMeasurementChange = (e) => {
        const { name, value } = e.target;
        if (value === '' || (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) >= 0)) {
            setMeasurements(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleFileChange = (event) => {
        const newFile = event.target.files[0];

        if (!newFile) return;

        if (!newFile.type.startsWith('image/')) {
            toast.error('Please select only image files');
            return;
        }

    if (newFile.size > 1024 * 1024) {
        toast.error('Image size should be less than 1MB');
        return;
    }

        if (images.length >= 4) {
            toast.error('Maximum 4 images allowed');
            return;
        }
        setImages(prevImages => [...prevImages, {
            file: newFile,
            preview: URL.createObjectURL(newFile)
        }]);
        event.target.value = '';
    };


    const removeImage = (index) => {
        setImages(prevImages => {
            URL.revokeObjectURL(prevImages[index].preview);
            return prevImages.filter((_, i) => i !== index);
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');
        const cus_id = localStorage.getItem('cus_id');

        if (!measurements.length || !measurements.width || !measurements.height || !measurements.weight) {
            toast.error('Please fill in all measurement fields');
            return;
        }

        const formData = new FormData();
        formData.append("waybill", waybill_no);
        formData.append("status", "Raise");

        Object.entries(measurements).forEach(([key, value]) => {
            formData.append(key, value);
        });
        images.forEach(image => {
            formData.append("images[]", image.file);
        });
       
        try {
            const response = await axios.post(Base_Url + dispute_action, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': token,
                    'cus_id': cus_id,
                },
            });

            if (response.data.status === "SUCCESS") {
                toast.success(response.data.message);
                onClose();
                setImages([]);
                setMeasurements({
                    length: '',
                    width: '',
                    height: '',
                    weight: ''
                });
            } else {
                toast.error(response.data.message );
            }
        } catch (error) {
            toast.error("Error uploading data: " + error.message);
        }
    };


    React.useEffect(() => {
        // Cleanup function to revoke all object URLs when component unmounts
        return () => {
            images.forEach(image => URL.revokeObjectURL(image.preview));
        };
    }, [images]);

    return (
        <Dialog
            open={isopen}
            onClose={onClose}
            maxWidth="sm"
            sx={{
                '& .MuiDialog-paper': {
                    width: '80%',
                    height: '80vh',
                }
            }}
        >
            <DialogTitle className="bg-customPurple text-white text-center">
                <h2 className="text-2xl font-semibold flex items-center justify-center">
                    Upload Weight Dispute
                </h2>
                <IoIosCloseCircle
                    className="absolute top-2 right-2 cursor-pointer"
                    size={32}
                    onClick={onClose}
                />
            </DialogTitle>
            <DialogContent
                className={`shadow-custom-light rounded-custom dark:bg-[rgba(1,14,78,0.96)] custom-scrollbar dark:shadow-custom-dark mt-4 ml-2 mr-2 mb-4 p-4 
                ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}
            >
                <div className={`overflow-x-auto md:mt-8 md:mb-8 md:ml-8 md:mr-8 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                                    Length (cm)
                                </label>
                                <input
                                    type="text"
                                    name="length"
                                    value={measurements.length}
                                    onChange={handleMeasurementChange}
                                    className="w-full p-2 border border-gray-300 dark:bg-[rgba(1,14,78,0.96)]  rounded-custom dark:shadow-custom-dark focus:ring-purple-500 focus:border-purple-500"
                                    placeholder="Enter length"
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                                    Width
                                </label>
                                <input
                                    type="text"
                                    name="width"
                                    value={measurements.width}
                                    onChange={handleMeasurementChange}
                                    className="w-full p-2 dark:bg-[rgba(1,14,78,0.96)]  rounded-custom dark:shadow-custom-dark border border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                                    placeholder="Enter width"
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                                    Height
                                </label>
                                <input
                                    type="text"
                                    name="height"
                                    value={measurements.height}
                                    onChange={handleMeasurementChange}
                                    className="w-full p-2 border dark:bg-[rgba(1,14,78,0.96)]  rounded-custom dark:shadow-custom-dark border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                                    placeholder="Enter height"
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                                    Weight
                                </label>
                                <input
                                    type="text"
                                    name="weight"
                                    value={measurements.weight}
                                    onChange={handleMeasurementChange}
                                    className="w-full p-2 border dark:bg-[rgba(1,14,78,0.96)]  rounded-custom dark:shadow-custom-dark border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500"
                                    placeholder="Enter weight"
                                />
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <span className="text-sm font-medium text-gray-700 dark:text-white">
                                    Images ({images.length}/4)
                                </span>
                                <label
                                    className={`px-4 py-2 bg-customPurple text-white rounded-lg cursor-pointer hover:bg-purple-700 transition-colors
                                        ${images.length >= 4 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    Add Image
                                    <input
                                        type="file"
                                        className="hidden"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={images.length >= 4}
                                    />
                                </label>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                {images.map((image, index) => (
                                    <div key={index} className="relative group">
                                        <img
                                            src={image.preview}
                                            alt={`Preview ${index + 1}`}
                                            className="w-full h-32 object-cover rounded-lg"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => removeImage(index)}
                                            className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                                        >
                                            <FiX size={16} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className={`w-full px-4 py-2 text-white rounded-lg transition-all duration-300 
                                ${(!measurements.length || !measurements.width || !measurements.height || !measurements.weight)
                                    ? 'bg-customPurple opacity-50 cursor-not-allowed'
                                    : 'bg-customPurple hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50'
                                }`}
                            disabled={!measurements.length || !measurements.width || !measurements.height || !measurements.weight}
                        >
                            Submit Dispute
                        </button>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default StatusRaised;