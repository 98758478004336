
import React, { useEffect, useState } from 'react';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { FiDownload } from "react-icons/fi";
import { Base_Url, ratecsvuplode, fetchUser } from '../../../config/config'
import { toast } from 'react-toastify';
import { Upload, X, Eye } from 'lucide-react';
import axios from 'axios';
import Select from "react-dropdown-select";
import Papa from "papaparse";
import { useNavigate } from 'react-router-dom';

export default function SetRate() {
  const [limit, setLimit] = useState(10);
  const [file, setFile] = useState(null);   
  const [Id, setId] = useState('')
  const [isUploading, setIsUploading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const navigate = useNavigate()

  useEffect(() => {
    fetchCustomers();
  }, [])


  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile && selectedFile.type === 'text/csv') {
  //     setFile(selectedFile);
  //   } else {
  //     toast.error('Please select a valid CSV file.');
  //     event.target.value = null;
  //   }
  // };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    // Parse CSV for preview
    Papa.parse(uploadedFile, {
      complete: (results) => {
        setPreviewData(results.data);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!Id) {
      toast.error('Please select a seller first.');
      return;
    }
    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');
    setIsUploading(true);
    if (file) {
      const formData = new FormData();
      formData.append('csv_file', file);
      formData.append('seller_id', Id)
      try {
        const response = await axios.post(Base_Url + "admin/"+ ratecsvuplode, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'token': token,
            'cus_id': cus_id,
          },
        });
        setFile(null);
        if (response.data.status === "success") {
          toast.success(response.data.message);
          setIsUploading(false);
          navigate('/dasboard');
        } else {
          toast.error("File uploading failed");
          setIsUploading(false);
        }
      } catch (error) {
        toast.error("Error uploading file: " + error.message);
        setIsUploading(false);

      }
    }
  };
  const openPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };
  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };
  const fetchCustomers = async () => {
    
    // const url = `${Base_Url}admin/${fetchUser}?page=${currentPage}&limit=${limit}`;
    const url = Base_Url + fetchUser;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "success") {
        // const filteredCustomers = data.data.id;
        // const Count = data.data.filter(customer => customer.role !== 'admin').length;
        // toast.success(data.message);
        // console.log("darata",filteredCustomers)
        // setCustomers(filteredCustomers.filteredCustomers);
        // setTotalCustomers(Count || 0);
        const transformedData = data.data.map(user => ({
          value: user.id,
          label: user.id,
        }));
        setCustomers(transformedData);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  }
  const handleSelectChange = (selected) => {
    if (selected.length > 0) {
      const selectedItem = selected[0];
      setId(selectedItem.value);
      console.log(selectedItem);
    }
  };

  const PreviewModal = () => {
    if (!previewData || previewData.length === 0) return null;

    // Get column headers
    const headers = Object.keys(previewData[0]);


    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className={`bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-lg shadow-xl w-11/12 max-w-4xl max-h-[90vh] overflow-hidden`}>
          <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
            <h2 className="text-xl font-bold dark:text-white">File Preview</h2>
            <button
              onClick={closePreviewModal}
              className="text-gray-600 dark:text-white hover:text-gray-900"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="overflow-auto max-h-[70vh]">
            <table className="w-full">
              <thead className="sticky top-0 bg-gray-100 dark:bg-[rgba(1,14,78,0.96)]">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="p-2 border dark:border-gray-700 text-left font-semibold dark:text-white"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {previewData.slice(0, 10).map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="hover:bg-gray-50 dark:hover:bg-[rgba(255,255,255,0.1)]"
                  >
                    {headers.map((header, colIndex) => (
                      <td
                        key={colIndex}
                        className="p-2 border dark:border-gray-700 dark:text-white"
                      >
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* {previewData.length > 10 && (
              <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                Showing first 10 rows of {previewData.length} total rows
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      {isPreviewModalOpen && <PreviewModal />}

      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: '113px', height: 'calc(100vh - 115px)', overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}
        <div className='shadow-custom-light dark:shadow-custom-dark rounded-custom w-full mt-6 mb-3 mr-3 ml-3'>
          <div className={`p-4 md:p-6 min-h-[50vh] custom-scrollbar overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] ${isToggleSidebar ? 'ml-0' : 'mr-0'} ${isToggleSidebar ? 'w-full' : 'w-full'} mb-8 md:mb-0`}>
            <div className="max-w-4xl mx-auto">
              <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark shadow-custom-light rounded-custom overflow-hidden">
                <div className="bg-customPurple p-6 text-white rounded-custom shadow-custom-light dark:shadow-custom-dark">
                  <h2 className="text-2xl font-bold">Upload Rate Card</h2>
                  <p className="text-purple-200">Upload a CSV file for Surface or Express Rates</p>
                </div>
                <div className='mt-4 ml-2 mr-2 '>
                  <label htmlFor="Id" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                    Select Id
                  </label>
                  <Select options={customers} style={{
                    padding: '0.5rem',
                    appearance: 'none',
                    borderRadius: '0.375rem',
                    width: '100%',
                    outline: 'none',
                    backgroundColor: isDarkMode ? 'rgba(1, 14, 78, 0.96)' : '',
                    color: isDarkMode ? 'white' : 'rgba(1, 14, 78, 0.96)',
                  }}
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="p-2">
                  <div className="mb-8">
                    <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                      Select a CSV file
                    </label>
                    <div className="relative rounded-md shadow-lg flex flex-col md:flex-row">
                      <div className="relative flex-grow mb-4 md:mb-0 md:mr-4">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <Upload className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                          type="file"
                          name="file-upload"
                          id="file-upload"
                          accept=".csv"
                          className={`block w-full pl-10 pr-12 py-3 shadow-custom-light dark:shadow-custom-dark rounded-custom border-gray-300 text-lg dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:placeholder-white placeholder-custom-gray`}
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="flex justify-center md:block space-x-2">
                        <button
                          type="button"
                          onClick={handleUpload}
                          disabled={!file || isUploading}
                          className={`inline-flex items-center sm:px-6 sm:py-3  px-3 py-1 mb-3 border border-transparent text-base font-medium rounded-md text-white transition-all duration-300 ease-in-out ${!file || isUploading
                            ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                            : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                            }`}
                        >
                          {isUploading ? (
                            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                            </svg>
                          ) : (
                            <Upload className="h-5 w-5 mr-4 text-white" aria-hidden="true" />
                          )}
                          {isUploading ? 'Uploading...' : 'Upload'}
                        </button>
                        {file && previewData && (
                          <button
                            type="button"
                            onClick={openPreviewModal}
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 transition-all duration-300 ease-in-out"
                          >
                            <Eye className="h-5 w-5 mr-4 text-white" aria-hidden="true" />
                            Preview
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-6 ml-8">
                  Choose a CSV file with rate card details and click Upload.
                </p>
                <div className="mt-6 text-center">
                        <a
                            href="/sampleFile/ratecard 5.csv"
                            download="ratecard.csv"
                            className="w-full inline-flex items-center justify-center px-4 py-2 text-white bg-customPurple rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300"
                        >
                            <FiDownload className="w-5 h-5 mr-2" />
                            Download RateCard template
                        </a>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}