// import React, { useState, useEffect } from 'react';
// import { Package2, Search, MapPin, CalendarClock, Truck, CheckCircle2, Circle } from 'lucide-react';
// import NavBar from '../../../component/navBar/navBar';
// import Sidebar from '../../../component/sidebar/sidebar';
// import { useMyContext } from '../../../context/MyContext';
// import '../../../css/custom-scrollbar.css';
// import axios from 'axios';
// import { Base_Url, track } from '../../../config/config';

// const OrderTracking = () => {
//   const [waybillNumber, setWaybillNumber] = useState('');
//   const [orderTrackingInfo, setOrderTrackingInfo] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');
//   const { isDarkMode, isToggleSidebar, orderid, setorderId } = useMyContext();

//   useEffect(() => {
//     if (orderid) {
//       handleTrack();
//     }
//   }, [orderid]);

//   const handleTrack = async () => {
//     const trackingNumber = orderid || waybillNumber;
//     if (!trackingNumber) {
//       setError('Please enter a valid order ID');
//       setOrderTrackingInfo(null);
//       return;
//     }

//     setIsLoading(true);
//     setError('');

//     const token = localStorage.getItem('token');
//     const cus_id = localStorage.getItem('cus_id');

//     try {
//       const response = await axios.post(Base_Url + track, {
//         order_id: waybillNumber || orderid,
//         shipment_type: 'Forward'
//       }, {
//         headers: {
//           'token': token,
//           'cus_id': cus_id,
//           'Content-Type': 'application/json'
//         }
//       });

//       setOrderTrackingInfo(response.data.data.ShipmentData[0].Shipment);
//       setError('');
//       setorderId(null);
//     } catch (err) {
//       setError('Failed to fetch tracking information. Please try again.');
//       setOrderTrackingInfo(null);
//       console.error('Error:', err.response ? err.response.data : err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getStepIcon = (scan, isLatest) => {
//     
//     const status = scan.ScanDetail.Scan.toLowerCase();
//     if (status) {
//       return <CheckCircle2 className="w-6 h-6 text-green-500 animate-bounce" />;
//     }

//     if (isLatest) {
//       return (
//         <div className="relative inline-flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 animate-pulse">
//           <Truck className="w-4 h-4 text-purple-600" />
//         </div>
//       );
//     }

//     return <Circle className="w-6 h-6 text-gray-400" />;
//   };

//   return (
//     <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-gray-50'}`}>
//       <div className="z-10">
//         <NavBar />
//       </div>

//       <div className="flex flex-1 overflow-hidden">
//         {isToggleSidebar && (
//           <div className="fixed z-20 h-full w-64 md:relative duration-300 bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20"
//             style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}>
//             <Sidebar />
//           </div>
//         )}
//         <div className="w-full ml-3 custom-scrollbar overflow-auto mr-3 mt-4  mb-4 mx-auto bg-white dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark">
//         <div className="flex-1 p-4 md:p-8 overflow-auto">
//           <div className="max-w-sm mx-auto bg-white dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark">
//             <div className="p-6">
//               <div className="flex items-center space-x-2 mb-6">
//                 <Package2 className="w-6 h-6 text-purple-600" />
//                 <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Track Your Shipment</h1>
//               </div>

//               <div className="space-y-4">
//                 <div className="flex space-x-2">
//                   <input
//                     type="text"
//                     placeholder="Enter Order ID"
//                     value={waybillNumber}
//                     onChange={(e) => setWaybillNumber(e.target.value)}
//                     className="flex-1 px-4 py-2 border border-gray-300 rounded-custom focus:outline-none focus:ring-2 focus:ring-purple-500 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
//                   />
//                   <button
//                     onClick={handleTrack}
//                     disabled={isLoading}
//                     className=" px-2 py-2  bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 flex items-center space-x-2"
//                   >
//                     {isLoading ? (
//                       <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                     ) : (
//                       <>
//                         <Search className="w-4 h-4" />
//                         <span>Track</span>
//                       </>
//                     )}
//                   </button>
//                 </div>

//                 {error && (
//                   <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 dark:bg-red-900/20 dark:text-red-400">
//                     {error}
//                   </div>
//                 )}

//                 {orderTrackingInfo && (
//                   <div className="mt-8 space-y-8">
//                     <div className="p-4 bg-purple-50 dark:bg-purple-900/20 rounded-lg flex justify-between items-start">
//                       <div className="space-y-1">
//                         <p className="text-sm text-gray-500 dark:text-gray-400">Current Status</p>
//                         <p className="font-semibold text-purple-600 dark:text-purple-400">
//                           {orderTrackingInfo.Status.Status}
//                         </p>
//                       </div>
//                       <div className="text-right space-y-2">
//                         <div className="flex items-center justify-end space-x-2">
//                           <MapPin className="w-4 h-4 text-gray-400" />
//                           <span className="text-sm font-medium dark:text-white">{orderTrackingInfo.Destination}</span>
//                         </div>
//                         <div className="flex items-center justify-end space-x-2">
//                           {/* <CalendarClock className="w-4 h-4 text-gray-400" /> */}
//                           <span className="text-sm dark:text-gray-300">
//                           Awb:{orderTrackingInfo.AWB}
//                           </span>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="space-y-6">
//                       {orderTrackingInfo.Scans?.map((scan, index) => (
//                         <div key={index} className="relative">
//                           <div className="flex items-start space-x-4">
//                             <div className="flex-shrink-0">
//                               {getStepIcon(scan, index === 0)}
//                             </div>
//                             <div className="flex-1 space-y-1">
//                               <p className="font-medium dark:text-white">
//                                 {scan.ScanDetail.Instructions}
//                               </p>
//                               <p className="text-xs text-gray-400">
//                                 {new Date(scan.ScanDetail.StatusDateTime).toLocaleString()}
//                               </p>
//                               <p className="text-xs text-gray-400">
//                               {orderTrackingInfo?.Status?.Instructions}
//                               </p>
//                               <p className="text-sm text-gray-500 dark:text-gray-400">
//                                   {scan.ScanDetail.ScannedLocation}
//                                 </p>
//                             </div>
//                           </div>
//                           {index < orderTrackingInfo.Scans.length - 1 && (
//                             <div className="absolute left-3 top-8 w-0.5 h-16 bg-gray-200 dark:bg-gray-700" />
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     </div>        
//   );
// };
// export default OrderTracking;




import React, { useState, useEffect } from 'react';
import { Package2, Search, MapPin, CalendarClock, Truck, CheckCircle2, Circle, Package,IndianRupee, Clock, Phone, User, DollarSign, Box, MapPinned, Calendar } from 'lucide-react';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';
import axios from 'axios';
import { Base_Url, track } from '../../../config/config';


const OrderTracking = () => {
  const [waybillNumber, setWaybillNumber] = useState('');
  const [orderTrackingInfo, setOrderTrackingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { isDarkMode, isToggleSidebar, orderid, setorderId } = useMyContext();

  useEffect(() => {
    if (orderid) {
      handleTrack();
    }
  }, [orderid]);

  const handleTrack = async () => {
    const trackingNumber = orderid || waybillNumber;
    if (!trackingNumber) {
      setError('Please enter a valid order ID');
      setOrderTrackingInfo(null);
      return;
    }

    setIsLoading(true);
    setError('');

    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');

    try {
      const response = await axios.post(Base_Url + track, {
        order_id: waybillNumber || orderid,
        shipment_type: 'Forward'
      }, {
        headers: {
          'token': token,
          'cus_id': cus_id,
          'Content-Type': 'application/json'
        }
      });

      setOrderTrackingInfo(response.data.data.ShipmentData[0].Shipment);
      setError('');
      setorderId(null);
    } catch (err) {
      setError('Failed to fetch tracking information. Please try again.');
      setOrderTrackingInfo(null);
    } finally {
      setIsLoading(false);
    }
  };


  const formatDate = (dateString) => {
    if (!dateString) return "Not Available";
    return new Date(dateString).toLocaleString();
  };

  const DetailRow = ({ label, value, icon: Icon }) => (
    <div className="flex items-center justify-between py-2 border-b border-gray-100 dark:border-gray-700">
      <div className="flex items-center space-x-2">
        {Icon && <Icon className="w-4 h-4 text-gray-400" />}
        <span className="text-sm text-gray-500 dark:text-gray-400">{label}</span>
      </div>
      <span className="text-sm font-medium dark:text-white">{value || "Not Available"}</span>
    </div>
  );

  const getStepIcon = (scan, isLatest) => {
    const status = scan.ScanDetail.Scan.toLowerCase();
    if (status) {
      return <CheckCircle2 className="w-6 h-6 text-green-500 animate-bounce" />;
    }
    if (isLatest) {
      return (
        <div className="relative inline-flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 animate-pulse">
          <Truck className="w-4 h-4 text-purple-600" />
        </div>
      );
    } 
    return <Circle className="w-6 h-6 text-gray-400" />;
  };

  const DeliveryInfoPanel = () => (
    <div className="space-y-6">
      {/* Shipment Overview Card */}
      <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-]lg shadow-lg">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold dark:text-white flex items-center gap-2">
            <Package2 className="w-5 h-5 text-purple-600" />
            Shipment Overview
          </h2>
        </div>
        <div className="p-4 space-y-3">
          <DetailRow label="AWB Number" value={orderTrackingInfo?.AWB} icon={Package} />
          <DetailRow label="Reference No" value={orderTrackingInfo?.ReferenceNo} icon={Package2} />
          <DetailRow label="Order Type" value={orderTrackingInfo?.OrderType} icon={Box} />
          <DetailRow label="Invoice Amount" value={`₹${orderTrackingInfo?.InvoiceAmount || 0}`} icon={IndianRupee} />
          <DetailRow label="COD Amount" value={`₹${orderTrackingInfo?.CODAmount || 0}`} icon={IndianRupee} />
          <DetailRow label="Quantity" value={orderTrackingInfo?.Quantity} icon={Box} />
        </div>
      </div>

      {/* Location Details */}
      <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-lg shadow-lg">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold dark:text-white flex items-center gap-2">
            <MapPinned className="w-5 h-5 text-blue-600" />
            Location Details
          </h2>
        </div>
        <div className="p-4 space-y-3">
          <DetailRow label="Origin" value={orderTrackingInfo?.Origin} icon={MapPin} />
          <DetailRow label="Destination" value={orderTrackingInfo?.Destination || "Not specified"} icon={MapPin} />
          <DetailRow label="Pickup Location" value={orderTrackingInfo?.PickupLocation} icon={MapPin} />
        </div>
      </div>

      {/* Consignee Details */}
      <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-lg shadow-lg">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold dark:text-white flex items-center gap-2">
            <User className="w-5 h-5 text-green-600" />
            Consignee Details
          </h2>
        </div>
        <div className="p-4 space-y-3">
          {orderTrackingInfo?.Consignee && (
            <>
              <DetailRow label="Name" value={orderTrackingInfo.Consignee.Name} icon={User} />
              <DetailRow label="City" value={orderTrackingInfo.Consignee.City || orderTrackingInfo.Consignee.State} icon={MapPin} />
              <DetailRow label="Pin Code" value={orderTrackingInfo.Consignee.PinCode} icon={MapPin} />
              <DetailRow label="Country" value={orderTrackingInfo.Consignee.Country} icon={MapPin} />
            </>
          )}
        </div>
      </div>

      {/* Dates Information */}
      <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-lg shadow-lg">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold dark:text-white flex items-center gap-2">
            <Calendar className="w-5 h-5 text-orange-600" />
            Important Dates
          </h2>
        </div>
        <div className="p-4 space-y-3">
          <DetailRow label="Pickup Date" value={formatDate(orderTrackingInfo?.PickUpDate)} icon={Calendar} />
          <DetailRow label="Expected Delivery" value={formatDate(orderTrackingInfo?.ExpectedDeliveryDate)} icon={Calendar} />
          <DetailRow label="First Attempt Date" value={formatDate(orderTrackingInfo?.FirstAttemptDate)} icon={Calendar} />
        </div>
      </div>

      {/* Status Banner */}
      <div className="p-4 bg-purple-50 dark:bg-purple-900/20 rounded-lg">
        <div className="flex items-center space-x-3 mb-2">
          <Clock className="w-5 h-5 text-purple-600" />
          <h3 className="font-medium dark:text-white">Current Status</h3>
        </div>
        <div className="space-y-2">
          <p className="text-sm font-medium text-purple-600 dark:text-purple-400">
            {orderTrackingInfo?.Status?.Status}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {orderTrackingInfo?.Status?.Instructions}
          </p>
          <p className="text-sm text-gray-500">
            Last Updated: {formatDate(orderTrackingInfo?.Status?.StatusDateTime)}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-gray-50'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className="fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20"
            style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}>
            <Sidebar />
          </div>
        )}

        <div className="flex-1 p-4 md:p-8 overflow-auto">
          {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 max-w-7xl mx-auto"> */}
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-5 max-w-7xl mx-auto">
            {/* Main Tracking Section */}
            <div className="lg:col-span-2 bg-white dark:bg-[rgba(1,14,78,0.96)] shadow-lg rounded-lg">
              <div className="p-6">
                <div className="flex items-center space-x-2 mb-6">
                  <Package2 className="w-6 h-6 text-purple-600" />
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Track Your Shipment</h1>
                </div>

                <div className="space-y-4">
                  {/* Search section and tracking timeline remain the same */}
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      placeholder="Enter Order ID"
                      value={waybillNumber}
                      onChange={(e) => setWaybillNumber(e.target.value)}
                      className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                    />
                    <button
                      onClick={handleTrack}
                      disabled={isLoading}
                      className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 flex items-center space-x-2"
                    >
                      {isLoading ? (
                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      ) : (
                        <>
                          <Search className="w-4 h-4" />
                          <span>Track</span>
                        </>
                      )}
                    </button>
                  </div>

                  {error && (
                    <div className="p-4 bg-red-50 border-l-4 border-red-500 text-red-700 dark:bg-red-900/20 dark:text-red-400">
                      {error}
                    </div>
                  )}

                  {orderTrackingInfo && (
                    <div className="space-y-6 mt-8">
                      {orderTrackingInfo.Scans?.map((scan, index) => (
                        <div key={index} className="relative">
                          <div className="flex items-start space-x-4">
                            <div className="flex-shrink-0">
                              {getStepIcon(scan, index === 0)}
                            </div>
                            <div className="flex-1 space-y-1">
                              <p className="font-medium dark:text-white">
                                {scan.ScanDetail.Instructions}
                              </p>
                              <p className="text-xs text-gray-400">
                                {formatDate(scan.ScanDetail.StatusDateTime)}
                              </p>
                              <p className="text-sm text-gray-500 dark:text-gray-400">
                                {scan.ScanDetail.ScannedLocation}
                              </p>
                            </div>
                          </div>
                          {index < orderTrackingInfo.Scans.length - 1 && (
                            <div className="absolute left-3 top-8 w-0.5 h-16 bg-gray-200 dark:bg-gray-700" />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Info Panel */}
            <div className="lg:col-span-3">
              <DeliveryInfoPanel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;