import React, { useState, useEffect, useRef, useMemo,useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { RxDashboard } from "react-icons/rx";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { CiCircleAlert, CiBank, CiSettings } from "react-icons/ci";
import { LuWarehouse } from "react-icons/lu";
import { PiAsteriskSimpleThin } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { GoMilestone } from "react-icons/go";
import { MdSupportAgent } from "react-icons/md";
import { MdGroups2 } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { useMyContext } from "../../context/MyContext";
import '../css/custom-scrollbar.css';
// import { Toaster,toast } from "react-hot-toast";
import { useAuth } from "../../routes/Auth";
import { LogOut } from 'lucide-react';
import { Lock } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Sidebar = () => {
  const { profile_flag } = useMyContext();
  const [activeTab, setActiveTab] = useState(-1);
  const [pageTab, setPageTab] = useState(-1);
  const [activeSubTab, setActiveSubTab] = useState(-1);
  const { isToggleSidebar, setIsToggleSidebar, isDarkMode } = useMyContext();
  const navigate = useNavigate();
  const { logout } = useAuth()
  const sidebarRef = useRef(null)
  const userRole = localStorage.getItem("role");



const toggleSubmenu = useCallback(
  (index, href) => {
    if (profile_flag === "0" && userRole !== "admin") {
      toast.error("Please Contact to the Support Team, Your KYC is Pending");
      return;
    }
    if (href) {
      navigate(href);
      // setIsToggleSidebar(false);
    } else {
      setActiveTab((prevActiveTab) => (prevActiveTab === index ? null : index));
    }
  },
  [profile_flag, userRole, navigate, setActiveTab]
);

  let menuItems = [
    {
      title: "Dashboard",
      icon: <RxDashboard />,
      href: "/dashboard",
      submenu: [],
    },
    {
      title: "Shipments",
      icon: <IoCreateOutline />,
      submenu: [
        { title: "Forward Shipment", href: "/shipments/forward" },
        { title: "Reverse Shipment", href: "/shipments/reverse" },
        { title: "Track Shipments", href: "/shipments/tracking" },
        { title: "Pickup Request", href: "/shipments/pickuprequest" },
        { title: "View Shipments", href: "/shipments/all" },
      ],
    },
    {
      title: "Warehouse",
      icon: <LuWarehouse />,
      submenu: [
        { title: "Add Warehouse", href: "/warehouse/add" },
        { title: "All Warehouses", href: "/warehouse/all" },
      ],
    },
    {
      title: "Disputes",
      icon: <CiCircleAlert />,
      submenu: [
        { title: "NDR", href: "/disputes/NDR" },
        // { href: "/disputes/RTO" },
        { title: "Weight Disputes", href: "/disputes/weight" },
        { title: "Weight Upload", href: "/disputes/wightUpload" },
        { title: "All Weight Disputes", href: "/disputes/allWeightDisputes" },
      ],
    },
    {
      title: "Payments",
      icon: <CiBank />,
      submenu: [
        // { title: "Remmitance", href: "/payments/remmitance" },
        // { title: "Invoice", href: "/payments/invoice" },
        { title: "Wallet Balance", href: "/payments/walletbalance" },
        // { title: "Payments History", href: "/payments/history" },  
      ],
    },
    // {
    //   title: "Risk",
    //   icon: <PiAsteriskSimpleThin />,
    //   submenu: [
    //     { title: "Claims", href: "/risk/claims/lost&damages" },
    //   ],
    // },
    {
      title: "Services",
      icon: <GoMilestone />,
      href: "/services",
      submenu: [],
    },
    {
      title: "Customers",
      icon: <RiGroupLine />,
      submenu: [
        { title: "ViewAllCustomer", href: "/customers/viewCustomer" },
        // { title: "Add customer", href: "/customers/addCustomer" },
        { title: "KYC Pending", href: "/customers/kycPending" },
      ],
    },
    {
      title: "Settings",
      icon: <CiSettings />,
      submenu: [
        { title: "Rate Calculator", href: "/settings/rateCalculator" },
        { title: "Servicable Pincodes", href: "/settings/pincode" },
        { title: "Rate Card", href: "/settings/rateCard" },
        // { title: "Zone", href: "/settings/account" },
        // { title: "Add Pincodes", href: "/settings/addPincode" },
        { title: "Set Rate", href: "/settings/setRate" },
      ],
    },
    {
      title: "Reports",
      href: "/reports",
      icon: <TbReport />,
      submenu: [],
    },
    {
      title: "Help",
      href: "/help",
      icon: <MdSupportAgent />,
      submenu: [],
    },
    {
      title: "Profile",
      icon: <CgProfile />,
      href: "/profile",
      submenu: [],
    },
  ];
   
 if (userRole !== "admin") {
    menuItems.forEach(item => {
      if (item.submenu && item.title === "Disputes") {
        item.submenu = item.submenu.filter(subItem => subItem.title !== "Weight Upload");
      }
      if (item.submenu && item.title === "Disputes") {
        item.submenu = item.submenu.filter(subItem => subItem.title !== "All Weight Disputes");
      }
    });

    menuItems = menuItems.filter(item => item.title !== "Customers");
    menuItems.forEach(item => {
      if (item.submenu && item.title === "Settings") {
        item.submenu = item.submenu.filter(subItem => subItem.title !== "Set Rate");
      }
    });
  } else {
    menuItems.forEach(item => {
      if (item.submenu && item.title === "Settings") {
        item.submenu = item.submenu.filter(subItem => subItem.title !== "Rate Card");
      }
      if (item.submenu && item.title === "Disputes") {
        item.submenu = item.submenu.filter(subItem => subItem.title !== "Weight Disputes");
      }
    });
  }

  // const filterMenuItemsByRole = (menuItems, userRole) => {
  //   if (userRole !== "admin") {
  //     return menuItems.map(item => {
  //       if (item.submenu && item.title === "Disputes") {
  //         item.submenu = item.submenu.filter(subItem => !["Weight Upload", "All Weight Disputes"].includes(subItem.title));
  //       }
  //       if (item.submenu && item.title === "Settings") {
  //         item.submenu = item.submenu.filter(subItem => subItem.title !== "Set Rate");
  //       }
  //       return item;
  //     }).filter(item => item.title !== "Customers");
  //   } else {
  //     return menuItems.map(item => {  
  //       if (item.submenu && item.title === "Settings") {
  //         item.submenu = item.submenu.filter(subItem => subItem.title !== "Rate Card");
  //       }
  //       if (item.submenu && item.title === "Disputes") {
  //         item.submenu = item.submenu.filter(subItem => subItem.title !== "Weight Disputes");
  //       }
  //       return item;
  //     });
  //   }
  // };
  
  // useMemo(() => {
  //   menuItems = filterMenuItemsByRole(menuItems, userRole);
  // }, [userRole]);
  


  useMemo(() => {
    let currRoute = window.location.pathname
    menuItems.map((item, index) => {
      if (item.href && currRoute == item.href) {
        setActiveTab(index);
        setPageTab(index);
      }
      if (item.submenu) {
        item.submenu.map((subItem, subIndex) => {
          if (subItem.href && currRoute == subItem.href) {
            setActiveTab(index);
            setActiveSubTab(subIndex);
            setPageTab(index);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.innerWidth <= 768
      ) {
        setIsToggleSidebar(false);
      }
    };
    if (isToggleSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [isToggleSidebar, setIsToggleSidebar]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
    toast.success("LogOut Sucessfully!")
  }
  return (
    <>
      <ToastContainer />
      {isToggleSidebar && (
        <div ref={sidebarRef} className="w-64 h-full flex flex-col  transform transition-transform duration-300 pl-2 custom-scrollbar ">
          <ul className="flex-1">
            {menuItems.map((item, index) => (
              <li key={index}>
                <Button
                  sx={{
                    fontFamily: 'var(--font-custom-open-sans)',
                    textTransform: 'none',
                    color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                    width: '100%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    display: 'flex',
                    // paddingLeft:'1.5rem',
                    alignItems: 'center',
                    padding: '0.75rem 1rem',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(128,128,128, 0.3)',
                      color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => toggleSubmenu(index, item.href)}
                >
                  <span className={activeTab == index ? "icon mr-2 text-xl text-emerald-600 " : "icon mr-2 text-xl text-emerald-400 "}>
                    {item.icon}
                  </span>
                  <span className="pl-3x">
                    {item.title}
                  </span>
                  <span
                    className="arrow ml-auto"
                  >
                    {item.submenu.length ? (activeTab === index ? <FaAngleDown /> : <FaAngleRight />) : ""}
                  </span>
                </Button>
                {item.submenu.length > 0 && activeTab === index && (
                  <div className="submenuWrapper pl-6">
                    <ul className="submenu text-custom-gray border-l pl-4 border-gray-600">
                      {item.submenu.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className={`py-1 px-1 dark:text-white ${activeTab === index && activeSubTab === subIndex
                            ? "text-customPurple font-bold dark:text-gray-700"
                            : "text-gray-700 font-bold dark:text-customPurple"
                            }`}
                        >
                          <Link
                            to={subItem.href}
                            className="text-sm font-semibold hover:opacity-70 hover:text-customPurple transition-opacity duration-300"
                            aria-label={subItem.title}
                            style={{
                              fontFamily: 'var(--font-custom-open-sans)',
                              color: isDarkMode ? (pageTab === index && activeSubTab === subIndex ? '#A76EDB' : 'white') : (activeTab === index && activeSubTab === subIndex ? '#A76EDB' : 'rgba(6, 6, 6, 0.616)'),
                            }}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {/* <span className="mt-12 mb-12 ml-10 w-32 font-sans">
          <Button onClick={handleLogout}  sx={{ textTransform: 'capitalize', 
            color : isDarkMode ? 'white' : 'white',
            backgroundColor:"#3B82F6",
            marginLeft:"42px",
            }} 
            className=' text-white bg-blue-500 hover:text-white hover:bg-blue-600 w-32 font-sans h-8 '>
          LogOut
        </Button>
        </span> */}
          <div className="bg-blue-200 p-6 rounded-lg shadow-md  md:mt-8 md:mb-10 mt-8 mb-10 relative">
            {/* <div className="absolute top-6 left-7 w-8 h-8  bg-blue-300 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
                <div className="absolute  right-4 bottom-16 w-12 h-12   bg-blue-200 rounded-full translate-x-1/3 translate-y-1/3"></div> */}
            <button
              onClick={handleLogout}
              className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-sans font-medium py-2 px-4 ml-7 rounded-md transition duration-300 ease-in-out w-28"
            >
              <LogOut className="mr-2 h-4 w-4" />
              LogOut
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
